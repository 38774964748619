import { Threebox } from 'threebox-plugin';
/**
 * Fly to given location base on the 3D state
 * @param {*} map - mapbox map object
 * @param {int[]} target - target location
 * @param {bool} threeDFlag - 3D state
 */
function flyToClick(map, target, threeDFlag) {
    let pitchToAim = map.getPitch();
    let bearingToAim = map.getBearing();
    if (!threeDFlag.current) {
        pitchToAim = 0;
    }
    if (target.current == undefined) {
        target.current = target;
    }
    map.flyTo({
        center: target.current,
        zoom: 18,
        pitch: pitchToAim,
        bearing: bearingToAim,
        speed: 0.6,
        curve: 1,
        essential: true
    });

}

/**
 * this function is used to get the color of the layer
 * @param {*} layerIdArr - array of layer ids
 * @param {*} resultCenter - array of the center of the result
 * @param {*} countryName - name of the country
 * @param {*} chosenLang - language of the map
 * @returns json object of the layer colors
 */
async function getLayerColor(layerIdArr, resultCenter, countryName, chosenLang) {
    let point_arr = resultCenter.current.map((el) => el.toString());
    let env = ''
    if (process.env.NODE_ENV) {
        env = 'dev.';
    }
    let url = `https://${env}app.snap.land/getColor/${countryName}?layerIdArr=${layerIdArr.current}&chosenLang=${chosenLang.current}&point_arr=${point_arr}`;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })
        .then(dataSec => {
            return dataSec.json()
        }).then((resSec => {
            return resSec;
        })).catch(error => console.log('There was an error:', error))
}

/**
 * function to get the city map response from the server
 * @param {*} city - city id
 * @param {*} countryName - name of the country
 * @returns an object of the city layer response
 */
async function getCityMapResponse(city, countryName) {
    let env = ''
    if (process.env.NODE_ENV) {
        env = 'dev.';
        let temp_res = {
            "newData": {
                "layer2360": {
                    "layer_id": 2360,
                    "layer type": "fill-extrusion"
                },
                "layer2356": {
                    "layer_id": 2356,
                    "layer type": "fill",
                    "results": []
                },
                "layer2358": {
                    "layer_id": 2358,
                    "layer type": "fill-extrusion",
                    "results": []
                },
                "layer2357": {
                    "layer_id": 2357,
                    "layer type": "fill",
                    "results": []
                },
                "layer2359": {
                    "layer_id": 2359,
                    "layer type": "fill-extrusion",
                    "results": []
                },
                "layer2374": {
                    "layer_id": 2374,
                    "layer type": "symbol"
                },
                "layer2375": {
                    "layer_id": 2375,
                    "layer type": "symbol"
                },
                "layer2376": {
                    "layer_id": 2376,
                    "layer type": "symbol"
                },
                "layer2377": {
                    "layer_id": 2377,
                    "layer type": "symbol"
                },
                "layer2389": {
                    "layer_id": 2389,
                    "layer type": "symbol"
                },
                "layer1930": {
                    "layer_id": 1930,
                    "layer type": "fill"
                },
                "layer3746": {
                    "layer_id": 3746,
                    "layer type": "fill"
                },
                "layer2304": {
                    "layer_id": 2304,
                    "layer type": "symbol"
                },
                "layer2361": {
                    "layer_id": 2361,
                    "layer type": "line"
                },
                "layer2371": {
                    "layer_id": 2371,
                    "layer type": "fill"
                },
                "layer2378": {
                    "layer_id": 2378,
                    "layer type": "line"
                },
                "layer2380": {
                    "layer_id": 2380,
                    "layer type": "line"
                },
                "layer2365": {
                    "layer_id": 2365,
                    "layer type": "fill"
                },
                "layer2366": {
                    "layer_id": 2366,
                    "layer type": "fill"
                },
                "layer2382": {
                    "layer_id": 2382,
                    "layer type": "fill"
                },
                "layer2364": {
                    "layer_id": 2364,
                    "layer type": "fill"
                },
                "layer2384": {
                    "layer_id": 2384,
                    "layer type": "fill"
                },
                "layer2383": {
                    "layer_id": 2383,
                    "layer type": "fill"
                },
                "layer2395": {
                    "layer_id": 2395,
                    "layer type": "fill"
                },
                "layer2381": {
                    "layer_id": 2381,
                    "layer type": "fill"
                },
                "layer2363": {
                    "layer_id": 2363,
                    "layer type": "symbol"
                },
                "layer2367": {
                    "layer_id": 2367,
                    "layer type": "fill"
                },
                "layer2396": {
                    "layer_id": 2396,
                    "layer type": "fill"
                },
                "layer2546": {
                    "layer_id": 2546,
                    "layer type": "fill"
                },
                "layer2547": {
                    "layer_id": 2547,
                    "layer type": "fill"
                },
                "layer2526": {
                    "layer_id": 2526,
                    "layer type": "symbol"
                },
                "layer2529": {
                    "layer_id": 2529,
                    "layer type": "fill"
                },
                "layer3578": {
                    "layer_id": 3578,
                    "layer type": "fill"
                },
                "layer2525": {
                    "layer_id": 2525,
                    "layer type": "fill"
                },
                "layer1499": {
                    "layer_id": 1499,
                    "layer type": "line"
                },
                "layer3034": {
                    "layer_id": 3034,
                    "layer type": "fill"
                },
                "layer3035": {
                    "layer_id": 3035,
                    "layer type": "fill"
                },
                "layer3066": {
                    "layer_id": 3066,
                    "layer type": "fill"
                },
                "layer3067": {
                    "layer_id": 3067,
                    "layer type": "fill"
                },
                "layer2353": {
                    "layer_id": 2353,
                    "layer type": "fill"
                },
                "layer2354": {
                    "layer_id": 2354,
                    "layer type": "fill"
                },
                "layer2355": {
                    "layer_id": 2355,
                    "layer type": "fill"
                },
                "layer2392": {
                    "layer_id": 2392,
                    "layer type": "fill"
                },
                "layer2530": {
                    "layer_id": 2530,
                    "layer type": "fill"
                },
                "layer3355": {
                    "layer_id": 3355,
                    "layer type": "fill"
                },
                "layer3356": {
                    "layer_id": 3356,
                    "layer type": "fill"
                },
                "layer3673": {
                    "layer_id": 3673,
                    "layer type": "line"
                },
                "layer3733": {
                    "layer_id": 3733,
                    "layer type": "symbol"
                },
                "layer1810": {
                    "layer_id": 1810,
                    "layer type": "fill"
                },
                "layer3728": {
                    "layer_id": 3728,
                    "layer type": "fill"
                },
                "layer2390": {
                    "layer_id": 2390,
                    "layer type": "symbol"
                },
                "layer2370": {
                    "layer_id": 2370,
                    "layer type": "symbol"
                },
                "layer2372": {
                    "layer_id": 2372,
                    "layer type": "symbol"
                },
                "layer2373": {
                    "layer_id": 2373,
                    "layer type": "symbol"
                }
            },
            "base_layer_id": "2360",
            "building_res_layer_id": "2358",
            "building_to_color_layer_id": "2359",
            "parcel_to_color_layer_id": "2357",
            "mapCenter": [
                "34.9817564",
                " 32.7996175"
            ],
            "parcel_results": "2356"
        }
        return temp_res;
    }
    let url = `https://${env}app.snap.land/layer_response/${countryName}/?city=${city}`;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })
        .then(dataSec => {
            return dataSec.json()
        }).then((resSec => {
            return resSec;
        })).catch(error => console.log('There was an error:', error))
}

/**
 * function to get the parcel buildings from the server based on the location of the user click
 * @param {*} countryName - name of the country
 * @param {*} city - name of the city
 * @param {*} lon - longitude of the location
 * @param {*} lat - latitude of the location
 * @returns  json object of the parcel id and buildings ids
 */
async function getParcelBuildings(countryName, city, lon, lat) {
    let env = ''
    if (process.env.NODE_ENV) {
        env = 'dev.';
    }
    let url = `https://${env}app.snap.land/getPacelBuildings/${countryName}?city=${city.current}&lon=${lon}&lat=${lat}`;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })
        .then(dataSec => {
            return dataSec.json()
        }).then((resSec => {
            return resSec;
        })).catch(error => console.log('There was an error:', error))
}

/**
 * function the get bbox of the feature in specific layer
 * @param {*} countryName - name of the country
 * @param {*} layerId - id of the layer
 * @param {*} id - id of the feature
 * @returns an array of the bbox
 */
async function getFeatureBox(countryName, layerId, id) {
    let env = ''
    if (process.env.NODE_ENV) {
        env = 'dev.';
    }
    let url = `https://${env}app.snap.land/getFeatureBox/${countryName}?layerId=${layerId}&snap_id_to_check=${id}`;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })
        .then(dataSec => {
            return dataSec.json()
        }).then((resSec => {
            return resSec;
        })).catch(error => console.log('There was an error:', error))
}


async function getMapLayersData(layerIdArr, countryName, chosenLang) {
    let env = ''
    if (process.env.NODE_ENV) {
        env = 'dev.';
    }
    let url = `https://${env}app.snap.land/getMapLayersData/${countryName}?layerIdArr=${layerIdArr}&chosenLang=${chosenLang.current}`;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })
        .then(dataSec => {
            return dataSec.json()
        }).then((resSec => {
            return resSec;
        })).catch(error => console.log('There was an error:', error))
}

/**
 * function that init the layers to load
 * @param {*} data - data of the layers from the app state
 * @param {*} layersDataAndColors - layers data and colors from the app state
 * @returns two arrays of the sources names and the results ids
 */
async function initLayersToLoad(data, layersDataAndColors) {
    let resultsArr = [];
    let sourceArr = [];
    let res = layersDataAndColors.current;
    // await getMapLayersData(layersArr, countryName, chosenLang).then((res) => {
    for (const key in data.current) {
        sourceArr.push(key + '_source');

        let layerId = data.current[key]["layer_id"];
        let layerData = res.filter(function (item) { return item.id === layerId; });
        if (layerData.length === 0) {
            continue;
        }
        layerData = layerData[0];
        // if (res.length != 0) {
        //     res = res[0];
        // }

        if ((layerData["layer_type"] === "fill-extrusion") && layerData["visible_on_first_load"]) {
            if (data.current[key].hasOwnProperty("results")) {
                let arrayOfRes = data.current[key]["results"];
                for (let i = 0; i < arrayOfRes.length; i++) {
                    if (resultsArr.indexOf(arrayOfRes[i]) === -1) {
                        resultsArr.push(arrayOfRes[i]);
                    }
                }
            }
        }

    }
    // })
    return [sourceArr, resultsArr];
}
/**
 * function that disable 3D button
 */
function disable3DBtn() {
    let btn = document.getElementById("btn-3D-sec");
    btn.disabled = true;
    btn.classList.add('disable');
}
/**
 * function that enable 3D button
 */
function enable3DBtn() {
    let btn = document.getElementById("btn-3D-sec");
    btn.disabled = false;
    btn.classList.remove('disable');
}
/**
 * function that generate the mapbox match rule for the legend and layers coloring
 * @param {*} legendRes 
 * @returns match rule according to the layer legend column
 */
function generateMatchRule(legendRes) {
    let legArr;
    if (legendRes == undefined) {
        legArr = ['match', ['string', ['get', 'legend_col']]]
        legArr.push('rgba(0,0,0,0)')
        return legArr;
    }
    if (!Array.isArray(legendRes)) {
        legendRes = [legendRes]
    }
    let unique_legendRes = legendRes.reduce((unique, o) => {
        if (!unique.some(obj => obj['title'] === o['title'])) {
            unique.push(o);
        }
        return unique;
    }, []);
    legArr = ['match', ['string', ['get', 'legend_col']]]
    unique_legendRes.forEach(element => {
        legArr.push(element["title"])
        legArr.push(element["color"])
    });
    legArr.push('rgba(0,0,0,0)')
    return legArr;
}
/**
 * function that reterns the small, heigh and two mid values of the heatmap layer
 * @param {*} layerId 
 * @returns 
 */
async function getHeatMapValues(layerId) {
    let env = ''
    if (process.env.NODE_ENV) {
        env = 'dev.';
    }
    return fetch(`https://${env}app.snap.land/getHeatMapValues/?layerId=${layerId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })
        .then(dataSec => {
            return dataSec.json()
        }).then((resSec => {
            return resSec;
        })).catch(error => console.log('There was an error:', error))
}

/**
 * function the update the transaction layer on the map from the chart feature
 * @param {*} map - mapbox map
 */
async function update_transaction_to_map(map) {
    let geojson_to_update = await parse_transaction(1866);
    map.getSource('transaction_src').setData(geojson_to_update);
}

/**
 * function that parse the transaction data to geojson
 * @param {*} transactionsLayerId - the id of the transaction layer
 * @param {*} layersDataAndColors - layers data and colors from the app state
 * @param {*} dataForTransactionLayer - data for the transaction layer
 * @returns - geojson of the transaction layer
 */
async function parse_transaction(transactionsLayerId, layersDataAndColors, dataForTransactionLayer) {

    var geojson = {
        type: "FeatureCollection",
        features: [],
    };
    let res = layersDataAndColors.current;
    let layerData = res.filter(function (item) { return item.id == transactionsLayerId; });
    // await getLayerColorSecMap([id]).then((res) => {
    let leg_col = layerData[0]["legend_col"];
    let dataToadd = JSON.parse(JSON.stringify(dataForTransactionLayer));
    // let dataToadd = JSON.parse(JSON.stringify(advance_search_data));
    dataToadd.forEach(function (item) {
        let existing = geojson.features.filter(function (v, i) {
            return ((v.properties.gush == item.gush) && (v.properties.helka == item.helka));
        });

        if (existing.length) {
            // 
            let existingIndex = geojson.features.indexOf(existing[0]);
            let newItemFix = geojson.features[existingIndex]["properties"];
            newItemFix[layerData[0]["legend_col"]].push(item[layerData[0]["legend_col"]]);
            newItemFix[layerData[0]["id_column"]].push(numberWithCommas(item[layerData[0]["id_column"]]));
            let val = `${newItemFix[leg_col].length} עסקאות`;
            newItemFix["label_text"] = val;
            newItemFix["leg"] = 'black_diff';
            newItemFix["len"] = newItemFix["len"] + 1;
            geojson.features[existingIndex]["properties"] = newItemFix;
        } else {
            let newItem = JSON.parse(JSON.stringify(item));
            newItem[layerData[0]["legend_col"]] = [newItem[layerData[0]["legend_col"]]];
            newItem[layerData[0]["id_column"]] = [numberWithCommas(newItem[layerData[0]["id_column"]])];
            newItem["label_text"] = '₪ ' + newItem[layerData[0]["id_column"]][0] + ' למ״ר';
            newItem["leg"] = newItem[layerData[0]["legend_col"]][0];
            newItem["len"] = 1;
            geojson.features.push({
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": newItem["geom"]
                },
                "properties": newItem
            });
        }
    })
    return geojson;
}


/**
 * function that add the transaction layer to the map
 * @param {*} map  - mapbox map
 * @param {*} transactionsLayerId - the id of the transaction layer 
 * @param {*} layersDataAndColors - layers data and colors from the app state
 * @param {*} dataForTransactionLayer - data for the transaction layer
 * @param {*} transaction_popup - the popup of the transaction layer
 */
async function initTransactionLayersToMap(map, transactionsLayerId, layersDataAndColors, dataForTransactionLayer, transaction_popup) {

    let geojson_to_add = await parse_transaction(transactionsLayerId, layersDataAndColors, dataForTransactionLayer);
    // var geojson_to_add = {
    //     "type": "FeatureCollection",
    //     "name": "jsontemplate",
    //     "features": [
    //     { "type": "Feature", "properties": { "sum": "", "a2": "", "a3": "", "a4": ""}, "geometry": null }
    //     ]
    //     };


    map.addSource('transaction_src', {
        'type': 'geojson', 'data': geojson_to_add,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 25, // Radius of each cluster when clustering points 
        clusterProperties: { "sum": ["+", ["get", "len"]] }
    });
    let res = layersDataAndColors.current;
    let layerData = res.filter(function (item) { return item.id == transactionsLayerId; });
    let legArr = ['match', ['string', ['get', 'leg']]]
    layerData.forEach(element => {
        legArr.push(element["title"])
        legArr.push(element["color"])
    });
    legArr.push('rgb(74,75,74)')
    map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'transaction_src',
        filter: ['has', 'sum'],
        layout: {
            'visibility': 'none'
        },
        paint: {
            'circle-color': [
                'step',
                ['get', 'sum'],
                '#51bbd6',
                100,
                '#f1f075',
                750,
                '#f28cb1'
            ],
            'circle-radius': [
                'step',
                ['get', 'sum'],
                20,
                100,
                30,
                750,
                40
            ]
        }
    });


    // Add a layer showing the places.
    map.addLayer({
        'id': 'transaction_layer',
        'type': 'circle',
        'source': 'transaction_src',
        'filter': ['!', ['has', 'sum']],
        'layout': {
            'visibility': 'none'
        },
        'paint': {
            'circle-color': legArr,
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff'
        }
    });
    map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'transaction_src',
        filter: ['has', 'sum'],
        layout: {
            'text-field': '{sum}',
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 12,
            'visibility': 'none'
        }
    });
    map.loadImage(
        'https://v2.snap.land/vectors/map/transactions_on_map.png',
        (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker', image);
            // // Add a GeoJSON source with 2 points
            // map.addSource('points', {
            //     'type': 'geojson',
            //     'data': geojson_to_add
            // })
        }
    );

    // Add a symbol layer
    map.addLayer({
        'id': 'transaction_labels',
        'type': 'symbol',
        'source': 'transaction_src',
        'layout': {
            'icon-image': 'custom-marker',
            // get the title name from the source's "title" property
            'text-field': ['get', 'label_text'],
            'icon-anchor': 'bottom',
            'text-anchor': 'bottom',
            'text-offset': [0, -0.75],
            'visibility': 'none',
            'icon-allow-overlap': true,
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [5, 10, 10, 10],
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 12,
            'icon-size': 1
        },
        'paint': {
            'text-color': legArr
        }
    });
    // fillLayersSecMap.push("transaction_labels");
    map.setLayerZoomRange('transaction_labels', 16, 22)
    map.setLayerZoomRange('transaction_layer', 0, 16)

    map.on('click', 'transaction_labels', async function (e) {
        e.preventDefault();
        // e.stopPropagation();
        console.log("click on lable");
        // Copy coordinates array.

        let item = e.features[0].properties;
        let coordinates = e.features[0].geometry.coordinates.slice();
        let deal_hand = e.features[0].properties.deal_hand;
        let price_per_mr = e.features[0].properties.price_per_mr;
        if (JSON.parse(item[layerData[0]["legend_col"]]).length == 1) {
            return;
        }
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        // res = await getLayerColorSecMap([1866]);
        item[layerData[0]["legend_col"]] = JSON.parse(item[layerData[0]["legend_col"]])
        item[layerData[0]["id_column"]] = JSON.parse(item[layerData[0]["id_column"]])
        let t = createLabelForTransaction(item, layerData)
        // let s = nodeToString( t ).replace( "<" , "&lt;" ).replace( ">" , "&gt;");
        let s = nodeToString(t);
        // Populate the popup and set its coordinates
        // based on the feature found.
        transaction_popup.setLngLat(coordinates).setHTML(s).addTo(map);
    })

    map.on('click', 'transaction_layer', async function (e) {
        e.preventDefault();
        // e.stopPropagation();
        console.log("click on circule");

        // Copy coordinates array.
        let item = e.features[0].properties;
        let coordinates = e.features[0].geometry.coordinates.slice();
        let deal_hand = e.features[0].properties.deal_hand;
        let price_per_mr = e.features[0].properties.price_per_mr;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        // res = await getLayerColorSecMap([1866]);
        item[layerData[0]["legend_col"]] = JSON.parse(item[layerData[0]["legend_col"]])
        item[layerData[0]["id_column"]] = JSON.parse(item[layerData[0]["id_column"]])
        let t = createLabelForTransaction(item, layerData)
        // let s = nodeToString(t)
        // let s = nodeToString( t ).replace( "<" , "&lt;" ).replace( ">" , "&gt;");
        let s = nodeToString(t);
        // Populate the popup and set its coordinates
        // based on the feature found.
        transaction_popup.setLngLat(coordinates).setHTML(s).addTo(map);
    })
    // transaction_layer_loaded = true;
}
/**
 * function to convert html node to string
 * @param {*} node  - html node to convert
 * @returns a string of the html node
 */
function nodeToString(node) {
    var tmpNode = document.createElement("div");
    tmpNode.appendChild(node.cloneNode(true));
    var str = tmpNode.innerHTML;
    tmpNode = node = null; // prevent memory leaks in IE
    return str;
}

/**
 * function to add commas to numbers
 * @param {*} x - number to add commas to
 * @returns string of the number with commas
 */
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * function to create a label for a transaction layer
 * @param {*} element - the element to create the label for
 * @param {*} color_res  - the color result of the layer
 * @returns a div element of the label
 */
function createLabelForTransaction(element, color_res) {
    let divContent = document.createElement('div');
    // divContent.className = 'mapboxgl-popup-content';
    divContent.style.width = "fit-content";
    // divContent.style.padding = "5px 10px";
    divContent.style.direction = "rtl";
    let leg_col = color_res[0]["legend_col"];
    // if (element[leg_col].length > 1) {
    for (let ind in element[leg_col]) {
        divContent.className = 'mapboxgl-popup-content deal-desc';
        let divPrice = document.createElement('div');
        divPrice.style.direction = "rtl";
        divPrice.style.display = 'flex';
        let strong = document.createElement('div');
        let strong2 = document.createElement('div');
        // strong.id = "idForSetSecondd";
        // 
        let relevante_color = color_res.filter(function (item) { return item.title === element[leg_col][ind]; });
        let val = '';
        let colorToSet = '';
        if (relevante_color.length == 0) {
            let temp = color_res[0];
            val = element[temp["id_column"]][ind];
            colorToSet = 'rgb(0, 0, 0)';
        } else {
            relevante_color = relevante_color[0];
            val = element[relevante_color["id_column"]][ind];
            colorToSet = relevante_color["color"];

        }
        // val = Math.round((val / 1000) * 10) / 10;
        strong.innerHTML = " למ׳׳ר";
        // val = "" + val;
        strong2.innerHTML = "₪ " + val;
        strong2.style.marginLeft = '5px';
        strong.style.setProperty('color', colorToSet, 'important');
        strong2.style.setProperty('color', colorToSet, 'important');
        divPrice.appendChild(strong2);
        divPrice.appendChild(strong);
        divContent.appendChild(divPrice);
    }
    return divContent;
}

/**
 * function that loads layers to the map
 * @param {*} map - the map to load the layers to
 * @param {*} sourceArr - the source array of the layers
 * @param {*} clickedLayer - the clicked layer if there is one
 * @param {*} firstLoad - flag that indicates if it is the first load
 * @param {*} layerState - the current layer state
 * @param {*} layersData - the layers data
 * @param {*} changeBase - flag that indicates if the base map has changed
 * @param {*} building_to_color_layer_id - the building to color (click on map layer) layer id
 * @param {*} parcel_to_color_layer_id - the parcel to color (click on map layer) layer id
 * @param {*} resultsArr - the results array
 * @param {*} resultCenter - the result center
 * @param {*} tilesDomainName - the tiles domain name
 * @param {*} threeDFlag - the 3d flag
 * @param {*} layersDataAndColors - the layers data and colors
 * @param {*} setTransactionsId - the transactions id
 * @param {*} dataForTransactionLayer - the data for the transaction layer
 * @param {*} transaction_popup - the transaction popup
 * @param {*} setMivnanimLayerId - the mivnanim layer id setter function
 * @param {*} mivnanim_data - the mivnanim geojson data
 * @returns three arrays of 3d layers, fill layers and current active layers
 */
async function loadLayers(map, sourceArr, clickedLayer, firstLoad, layerState, layersData, changeBase, building_to_color_layer_id, parcel_to_color_layer_id, resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, setTransactionsId, dataForTransactionLayer, transaction_popup, setMivnanimLayerId, mivnanim_data) {
    disable3DBtn();
    let layers3D = [];
    let fillLayers = [];
    let activeLayers = [];
    let res = layersDataAndColors.current;
    // await getLayerColor(layersArr, resultCenter, countryName, chosenLang).then(async (res) => {

    for (let source in sourceArr) {
        source = sourceArr[source]
        let key;
        if (firstLoad.current) {
            key = source.replace(/_source/g, '');
        } else {
            if (layerState.current) {
                key = source.replace(/_source/g, '');
            } else {
                key = source.replace(/_source@/g, '');
            }
        }

        let currLayerObj = layersData.current[key];
        let layerId = currLayerObj["layer_id"].toString();
        if (currLayerObj["layer type"] == "transactions") {
            // set the transactions layer id with state set function
            setTransactionsId(layerId);
            // add the transactions layer to the map
            initTransactionLayersToMap(map, layerId, layersDataAndColors, dataForTransactionLayer, transaction_popup);
            if (!fillLayers.includes(layerId)) {
                fillLayers.push(layerId);
            }
            continue;
        } else if (currLayerObj["layer type"] == "mivnan_labels") {
            setMivnanimLayerId(layerId);
            add_mivnan_labels(map, mivnanim_data, layerId, layersDataAndColors);
            if (!fillLayers.includes(layerId)) {
                fillLayers.push(layerId);
            }
            continue;
        }
        // await getLayerColor(layerId).then((res) => {
        let layerData = res.filter(function (item) { return item.id == layerId; });
        if (layerData.length == 0) {
            continue;
        }
        let resForLegend;
        if (layerData[0]["legend_col"] != null) {
            resForLegend = layerData;
        }
        if (layerData.length === 1) {
            layerData = layerData[0];
        } else {
            resForLegend = layerData;
            layerData = layerData[0];
        }
        let type = layerData["layer_type"];
        let prevLayerName = layerId;
        let layerName = layerId;
        if (!firstLoad.current) {
            if (layerState.current) {
                prevLayerName += "@"
            } else {
                layerName += "@"
            }
        }
        let visibility;
        if (firstLoad.current) {
            if (changeBase) {
                // check later
                var isActiveSec = document.getElementById(`${layerId}`)
                // var isActiveSec = $(`#secondMap${layerId}`).hasClass('active');
                if (isActiveSec && activeLayers.indexOf(layerId) === -1) {
                    if (isActiveSec.classList.contains('active')) {
                        visibility = 'visible';
                        activeLayers.push(layerId);
                    } else {
                        visibility = 'none';
                    }
                } else if (layerId == building_to_color_layer_id || layerId == parcel_to_color_layer_id) {
                    visibility = 'visible';
                    activeLayers.push(layerId);
                }
                else {
                    visibility = 'none';
                }
            } else {
                visibility = layerData["visible_on_first_load"] ? 'visible' : 'none';
                if (visibility === 'visible' && activeLayers.indexOf(layerId) === -1) {
                    activeLayers.push(layerId);
                }
            }

        } else {
            visibility = map.getLayoutProperty(
                prevLayerName,
                'visibility'
            );
            if (layerId === clickedLayer) {
                if (visibility === 'none') {
                    visibility = 'visible';
                } else {
                    visibility = 'none';
                }
            }
        }
        let color = layerData["color"]
        if (color === "") {
            color = 'rgb(0,0,0)'
        }
        let arrForFunc = [];
        if (!layerData["is_independent"]) {
            arrForFunc = resultsArr.current
        } else if (currLayerObj.hasOwnProperty("results")) {
            arrForFunc = currLayerObj["results"]
        }
        let point_arr = resultCenter.current.map((el) => el.toString());

        map.addSource(source, {
            'type': 'vector',
            'tiles': ["https://" + tilesDomainName + "/public.snapland_load_layer_by_id8/{z}/{x}/{y}.pbf?layer_id=" + layerId + "&results_array={" + arrForFunc + "}&point_arr={" + point_arr + "}&active_layers_id={" + activeLayers + "}"],
            'minzoom': 0,
            'maxzoom': 22
        });
        let paintObj;
        let layoutObj;
        if (type === 'fill-extrusion') {
            paintObj = {
                'fill-extrusion-color': color,
                'fill-extrusion-opacity': 0.92,
                'fill-extrusion-height': {
                    'type': 'identity',
                    'property': 'height'
                },
                'fill-extrusion-base': {
                    'type': 'identity',
                    'property': 'base'
                }
            }
            layoutObj = {
                'visibility': visibility
            }
        } else if (type === 'fill-extrusion-extra-floors') {
            paintObj = {
                'fill-extrusion-color': ['get', 'fill'],
                'fill-extrusion-opacity': 0.92,
                'fill-extrusion-height': {
                    'type': 'identity',
                    'property': 'height'
                },
                'fill-extrusion-base': {
                    'type': 'identity',
                    'property': 'height_base'
                }
            }
            layoutObj = {
                'visibility': visibility
            }

        } else if (type === 'symbol') {
            paintObj = {}
            layoutObj = {
                'visibility': visibility,
                "icon-allow-overlap": true
            }
        } else if (type === 'line') {
            paintObj = {
                'line-color': color,
                'line-width': 6,
                'line-opacity': 0.8
            }
            layoutObj = {
                'visibility': visibility,
                'line-join': 'round',
                'line-cap': 'round'
            }

        } else if (type === 'heatmap') {
            await getHeatMapValues(layerId).then((res) => {

                let [dataForLayer] = res;
                let heatMapValues;
                ({ heatMapValues } = dataForLayer);
                var heatMapValuesInt = heatMapValues.map(function (x) {
                    return parseInt(x, 10);
                });
                let sortedList = Array.from(resForLegend).sort(function (a, b) {
                    const c = a.title,
                        d = b.title;
                    return c < d ? -1 : c > d ? 1 : 0;
                });

                paintObj = {
                    // Increase the heatmap weight based on frequency and property that chose
                    'heatmap-weight': {
                        property: layerData['legend_col'],
                        type: 'interval',
                        stops: [
                            [heatMapValuesInt[0], 0],
                            [heatMapValuesInt[1], 0.01],
                            [heatMapValuesInt[2], 0.5],
                            [heatMapValuesInt[3], 1]
                        ]
                    }
                    // [
                    //     'interpolate',
                    //     ['linear'],
                    //     ['get', res['id_column']],
                    //     0,
                    //     0,
                    //     3,
                    //     0.2,
                    //     6,
                    //     0.4,
                    //     10,
                    //     0.5,
                    //     14,
                    //     0.6,
                    //     18, 
                    //     0.8,
                    //     20,
                    //     1

                    // ]
                    ,
                    // Increase the heatmap color weight weight by zoom level
                    // heatmap-intensity is a multiplier on top of heatmap-weight
                    'heatmap-intensity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        1,
                        9,
                        3
                    ],
                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                    // Begin color ramp at 0-stop with a 0-transparancy color
                    // to create a blur-like effect.
                    // ['match', ['number', ['get', 'ischosen']], 1, res["color"], 'rgba(0,0,0,0)']
                    'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        sortedList[0]["color"],
                        0.2,
                        sortedList[1]["color"],
                        0.4,
                        sortedList[2]["color"],
                        0.6,
                        sortedList[3]["color"],
                        0.8,
                        sortedList[4]["color"],
                        1,
                        sortedList[5]["color"],
                    ],
                    // Adjust the heatmap radius by zoom level
                    'heatmap-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        2,
                        16,
                        20
                    ],
                    // Transition from heatmap to circle layer by zoom level
                    'heatmap-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        1,
                        9,
                        0.5
                    ]


                }
                layoutObj = {
                    'visibility': visibility
                }

            })
        } else {
            paintObj = {
                'fill-color': color,
                'fill-opacity': 0.5
            }
            layoutObj = {
                'visibility': visibility
            }
        }
        let final_type = type
        if (final_type == 'fill-extrusion-extra-floors') {
            final_type = 'fill-extrusion'
        }
        map.addLayer(
            {
                'id': layerName,
                'type': final_type,
                'source': source,
                'source-layer': 'public.snapland_sources',
                'layout': layoutObj,
                'paint': paintObj
            }
        )
        if (type === 'fill') {
            if (layerData["legend_col"] === null) {
                if (layerData["id_column"] != null) {
                    map.setPaintProperty(layerId, 'fill-color',
                        ['match', ['number', ['get', 'ischosen']], 1, layerData["color"], 'rgba(0,0,0,0)']
                    );
                    map.setPaintProperty(layerId, 'fill-outline-color',
                        ['match', ['number', ['get', 'ischosen']], 1, 'rgba(0,0,0,1)', 'rgba(0,0,0,0)']
                    );
                }
            } else {
                let legendRule = generateMatchRule(resForLegend)
                map.setPaintProperty(layerId, 'fill-color', legendRule);
            }
            if (firstLoad.current) {
                //update the active layers array
                if (map.getLayer(layerId)) {
                    if (!fillLayers.includes(layerId)) {
                        fillLayers.push(layerId)
                    }
                }
            }
        } else if (type === 'fill-extrusion') {
            if (firstLoad.current) {
                //update the active layers array
                if (map.getLayer(layerId)) {
                    if (!layers3D.includes(layerId)) {
                        layers3D.push(layerId)
                    }
                }
            }
            if (!threeDFlag.current) {
                map.setPaintProperty(layerName, 'fill-extrusion-height', 0);
                map.setPaintProperty(layerName, 'fill-extrusion-base', 0);
            }
        } else if (type === 'heatmap') {
            if (map.getLayer(layerName)) {
                if (!fillLayers.includes(layerName)) {
                    fillLayers.push(layerName)
                }
            }
        } else if (type === 'line') {
            if (layerData["legend_col"] !== null) {
                let legendRule = generateMatchRule(resForLegend)
                map.setPaintProperty(layerId, 'line-color', legendRule);
            }
            if (map.getLayer(layerName)) {
                if (!fillLayers.includes(layerName)) {
                    fillLayers.push(layerName)
                }
            }
        } else if (type === 'fill-extrusion-extra-floors') {
            let legendRule = generateMatchRule(resForLegend)
            map.setPaintProperty(layerId, 'fill-extrusion-color', legendRule);
        } else {
            //symbol layer
            map.loadImage(layerData["color"], function (error, image) {
                if (error) throw error;
                if (!map.hasImage(layerName)) {
                    map.addImage(layerName, image);
                }
                map.setLayoutProperty(layerName, 'icon-image', layerName);
                map.setLayoutProperty(layerName, 'icon-size', 0.5);
            });

            if (map.getLayer(layerName)) {
                if (!fillLayers.includes(layerName)) {
                    fillLayers.push(layerName)
                }
            }
        }



    }
    // })
    return [layers3D, fillLayers, activeLayers];
}

/**
 *  onClick event handler for 3D layers
 * @param {*} map - mapbox map object
 * @param {*} id - clicked layer id
 * @param {*} setReadyForNextMapClick - state setter for readyForNextMapClick
 * @param {*} setReadyForChangeCity - state setter for readyForChangeCity
 * @param {*} layerState - flag for layer state
 * @param {*} layersData  - layers data
 * @param {*} activeLayers - active layers array
 * @param {*} removeActiveLayers - state function for removing active layers
 * @param {*} resultsArr - results array
 * @param {*} setReadyToIdle - state setter for readyToIdle
 * @param {*} setNeedToDelete = state setter for needToDelete
 * @param {*} fillLayers - fill layers array
 * @param {*} addActiveLayers - state function for adding active layers
 * @param {*} building_to_color_layer_id - building to color layer id (click on map layer)
 * @param {*} parcel_to_color_layer_id  - parcel to color layer id (click on map layer)
 * @param {*} resultCenter - result center
 * @param {*} tilesDomainName - tiles server domain name
 * @param {*} threeDFlag - flag for 3D layers
 * @param {*} layersDataAndColors - layers data and colors
 * @param {*} spacialLayersId - spacial layers id  object
 * @param {*} addResultsArr - state function for adding results array
 * @param {*} removeResultsArr - state function for removing results array
 * @param {*} transactionsLayerVisibility - transactions layer visibility flag
 */
async function eventHandler3DLayers(map, id, setReadyForNextMapClick, setReadyForChangeCity, layerState, layersData, activeLayers, removeActiveLayers, resultsArr, setReadyToIdle, setNeedToDelete, fillLayers, addActiveLayers, building_to_color_layer_id, parcel_to_color_layer_id, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, spacialLayersId, addResultsArr, removeResultsArr, transactionsLayerVisibility) {

    disableMenu();
    setReadyForNextMapClick(false);
    setReadyForChangeCity(false);
    //disableCityDropDown();
    // let resp = await deleteSemaphore();
    let clickedLayer;
    clickedLayer = id;
    let visibility = '';
    let newClick = clickedLayer;

    if (layerState.current) {
        newClick += '@';
    }
    visibility = map.getLayoutProperty(
        newClick,
        'visibility'
    );
    // Toggle layer visibility by changing the layout object's visibility property.
    if (visibility === 'visible') {
        console.log('fix toggle width');
        // if (window.getComputedStyle(document.getElementById(`FindLayer${id}`)).display !== 'none') {
        //     // fix toggle width
        //     // $("#FindLayer" + id).animate({ width: 'toggle' }, 200);
        // }
        document.getElementById(id + '').classList.remove('active');

        let obj = layersData.current["layer" + id]

        let index = activeLayers.current.indexOf(clickedLayer);

        if (index > -1) {

            removeActiveLayers(clickedLayer);
            if (obj.hasOwnProperty("results")) {
                let arrayOfRes = obj["results"];
                for (let i = 0; i < arrayOfRes.length; i++) {
                    if (resultsArr.current.indexOf(arrayOfRes[i]) != -1) {
                        // fix
                        removeResultsArr(arrayOfRes[i])
                        // resultsArr.splice(resultsArr.indexOf(arrayOfRes[i]), 1)
                    }
                }
            }
            await reload3DLayers(map, clickedLayer, setReadyToIdle, setNeedToDelete, fillLayers, layersData, layerState, building_to_color_layer_id, parcel_to_color_layer_id, resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, transactionsLayerVisibility);
        }
    } else {
        console.log('fix toggle width');
        // if (window.getComputedStyle(document.getElementById(`FindLayer${id}`)).display !== 'none') {
        //     // fix toggle width
        //     // $("#FindLayer" + id).animate({ width: 'toggle' }, 200);
        // }
        document.getElementById(id + '').classList.add('active');
        let obj = layersData.current["layer" + id]
        if (activeLayers.current.indexOf(clickedLayer) === -1) {
            addActiveLayers(clickedLayer);
        }

        if (obj.hasOwnProperty("results")) {
            let arrayOfRes = obj["results"];
            for (let i = 0; i < arrayOfRes.length; i++) {
                if (resultsArr.current.indexOf(arrayOfRes[i]) === -1) {
                    // fix
                    addResultsArr(arrayOfRes[i])
                    // resultsArr.push();
                }
            }
        }

        await reload3DLayers(map, clickedLayer, setReadyToIdle, setNeedToDelete, fillLayers, layersData, layerState, building_to_color_layer_id, parcel_to_color_layer_id, resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, transactionsLayerVisibility);
    }
    await updateLegend(map, clickedLayer, spacialLayersId);
    enableMenu()
};

/**
 * onClick event handler for 2D layers
 * @param {*} map - mapbox map object
 * @param {*} id - clicked layer id
 * @param {*} layersData - layers data
 * @param {*} transactionsLayerVisibility - transactions layer visibility
 * @param {*} setTransactionsLayerVisibility - state setter for transactions layer visibility
 * @param {*} layerState - flag for layer state
 * @param {*} spacialLayersId - spacial layers id object
 */
async function eventHandler2DLayers(map, id, layersData, transactionsLayerVisibility, setTransactionsLayerVisibility, layerState, spacialLayersId) {
    disableMenu()
    let clickedLayer = id;
    if (clickedLayer == '1940') {
        clickedLayer = 'mivnan_labels';
    }
    let visibility;
    if (layersData.current["layer" + id]["layer type"] === "transactions") {
        visibility = transactionsLayerVisibility.current;
    } else {
        visibility = map.getLayoutProperty(
            clickedLayer,
            'visibility'
        );
    }

    if (visibility === 'visible') {
        if (layersData.current["layer" + id]["layer type"] === "transactions") {
            // remove_Transaction_to_map()
            hideTransactionLayer(map, setTransactionsLayerVisibility);
        } else {
            map.setLayoutProperty(
                clickedLayer,
                'visibility',
                'none'
            );
        }
        console.log('fix toggle width');
        // if (window.getComputedStyle(document.getElementById(`FindLayer${id}`)).display !== 'none') {
        //     // fix toggle width
        //     // $("#FindLayer" + id).animate({ width: 'toggle' }, 200);
        // }

        document.getElementById(id + '').classList.remove('active');
        //  check if the layer have related table 
        let layerBtnInTable = document.querySelector(`button[data-layer='${id}']`);

        // if so change the button in the table as well
        if (layerBtnInTable) {
            layerBtnInTable.innerText = 'הצגה על גבי מפה';
            layerBtnInTable.classList.remove("more_info_btn_outline");
        }
    } else {
        console.log('fix toggle width');
        // if (window.getComputedStyle(document.getElementById(`FindLayer${id}`)).display !== 'none') {
        //     // fix toggle width
        //     // $("#FindLayer" + id).animate({ width: 'toggle' }, 200);
        // }

        document.getElementById(id + '').classList.add('active');
        if (layersData.current["layer" + id]["layer type"] === "transactions") {
            showTransactionLayer(map, setTransactionsLayerVisibility);
        } else {
            map.setLayoutProperty(
                clickedLayer,
                'visibility',
                'visible'
            );
        }
        //  check if the layer have related table 
        let layerBtnInTable = document.querySelector(`button[data-layer='${id}']`);

        // if so change the button in the table as well
        if (layerBtnInTable) {
            layerBtnInTable.innerText = 'הסרה';
            layerBtnInTable.classList.add("more_info_btn_outline");
        }
    }
    await updateLegend(map, clickedLayer, spacialLayersId);
    if (layersData.current["layer" + id]["layer type"] === "symbol") {
        map.moveLayer(clickedLayer);
        if (transactionsLayerVisibility.current == 'visible') {
            moveTransactionLayerTop(map);
        }
    } else if (layersData.current["layer" + id]["layer type"] === "transactions") {
        moveTransactionLayerTop(map);
    }
    enableMenu()
}
/**
 * function to disable menu buttons
 */
function disableMenu() {
    document.querySelectorAll('#menu1 a').forEach((el) => el.classList.add("disabledMenuButton"));
    document.querySelectorAll('button[data-layer]').forEach((el) => el.disabled = true);
}
/**
 * function to enable menu buttons
 */
function enableMenu() {
    document.querySelectorAll('#menu1 a').forEach((el) => el.classList.remove("disabledMenuButton"));
    document.querySelectorAll('button[data-layer]').forEach((el) => el.disabled = false);
}

/**
 * function to reload 3D layers and delete the previous ones for smooth switch and for update the result arr
 * @param {*} map - mapbox map object
 * @param {*} clickedLayer - clicked layer id
 * @param {*} setReadyToIdle - state setter for ready to idle flag
 * @param {*} setNeedToDelete - state setter for need to delete flag
 * @param {*} fillLayers - fill layers object
 * @param {*} layersData - layers data
 * @param {*} layerState - flag for layer state
 * @param {*} building_to_color_layer_id - building to color layer id
 * @param {*} parcel_to_color_layer_id - parcel to color layer id
 * @param {*} resultsArr - results array
 * @param {*} resultCenter - result center
 * @param {*} tilesDomainName - tiles domain name
 * @param {*} threeDFlag - flag for 3D
 * @param {*} layersDataAndColors - layers data and colors
 */

async function reload3DLayers(map, clickedLayer, setReadyToIdle, setNeedToDelete, fillLayers, layersData, layerState, building_to_color_layer_id, parcel_to_color_layer_id, resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, transactionsLayerVisibility) {

    setReadyToIdle(false);
    let sourceArr = generate3DSourceArr(layersData, layerState);
    // await loadLayers(sourceArr, clickedLayer);
    await loadLayers(map, sourceArr, clickedLayer, false, layerState, layersData, false, building_to_color_layer_id, parcel_to_color_layer_id, resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors);

    // move up all symbol layers
    fillLayers.current.forEach((layer) => {
        if (layersData.current["layer" + layer]["layer type"] === "symbol") {
            map.moveLayer(layer);
            if (transactionsLayerVisibility.current == 'visible') {
                moveTransactionLayerTop(map);
            }
        } else if (layersData.current["layer" + layer]["layer type"] === "transactions") {
            moveTransactionLayerTop(map);
        } else if (layersData.current["layer" + layer]["layer type"] === "mivnan_labels") {
            map.moveLayer('mivnan_labels');
        }
    })
    // enable the delete flag so in the next idle event the code will delete the double layers
    setNeedToDelete(true);
    setReadyToIdle(true);
}

/**
 * function that update the legend
 * @param {*} map  - mapbox map object
 * @param {*} clickedLayer - clicked layer id
 * @param {*} spacialLayersId - spacial layers id
 * @returns 
 */
async function updateLegend(map, clickedLayer, spacialLayersId) {

    // disableMenu()
    if (clickedLayer == 'mivnan_labels') {
        return;
    }
    let legId = clickedLayer + "leg";
    if (clickedLayer == 1940) {
        return;
    }
    let currLayerLegend = document.getElementById(legId);
    if (clickedLayer == spacialLayersId.current['building_to_color_layer_id'] || clickedLayer == spacialLayersId.current['parcel_to_color_layer_id']) {
        let visibility = map.getLayoutProperty(clickedLayer, 'visibility');
        if (visibility === 'visible') {
            currLayerLegend.style.display = 'block';
        } else {
            currLayerLegend.style.display = 'none';
        }
        return;
    } else if (document.getElementById(clickedLayer).classList.contains('active')) {
        currLayerLegend.style.display = 'block';
    } else {
        currLayerLegend.style.display = 'none';
    }
}

/**
 * function to generate source array for the 3D layers only
 * @param {*} layerData - layers data
 * @param {*} layerState - flag for layer state
 * @returns source array of 3D layers
 */
function generate3DSourceArr(layerData, layerState) {
    let sourceArr = [];
    let preFix = '_source'

    if (!layerState.current) {
        preFix += '@';
    }

    Object.entries(layerData.current).forEach(([key, value]) => {
        if (layerData.current[key]["layer type"] === "fill-extrusion") {
            sourceArr.push(key + preFix);
        }
    });
    return sourceArr;
}

/**
 * onIdle event handler
 * @param {*} map - mapbox map object
 * @param {*} readyToIdle - state for ready to idle flag
 * @param {*} needToDelete - state for need to delete flag
 * @param {*} setNeedToDelete - state setter for need to delete flag
 * @param {*} setReadyForNextMapClick - state setter for ready for next map click flag
 * @param {*} setReadyForChangeCity - state setter for ready for change city flag
 * @param {*} layerState - flag for layer state
 * @param {*} setLayerState - state setter for layer state
 * @param {*} layers3D - 3D layers array
 * @param {*} firstLoad - flag for first load
 * @param {*} setFirstLoad - state setter for first load
 * @param {*} layersData - layers data
 * @param {*} popUp3D - 3D pop up
 * @param {*} transactionsLayerVisibility - transactions layer visibility
 */
async function mapOnIdleHandler(map, readyToIdle, needToDelete, setNeedToDelete, setReadyForNextMapClick, setReadyForChangeCity, layerState, setLayerState, layers3D, firstLoad, setFirstLoad, layersData, popUp3D, transactionsLayerVisibility) {
    if (readyToIdle.current) {
        moveDrawLayerTop(map);
        if (needToDelete.current) {
            layers3D.current.forEach(layer => {
                let prevSourceName = "layer" + layer + "_source";
                let prevLayer = layer;

                if (layerState.current) {
                    prevSourceName += "@";
                    prevLayer += "@";
                }
                deleteSourceAndLayer(map, prevSourceName, prevLayer)
            });

            setNeedToDelete(false);
            setReadyForNextMapClick(true);
            setReadyForChangeCity(true);
            setLayerState(!layerState.current);
            enableMenu();
            enable3DBtn();
        }

        if (firstLoad.current) {
            setFirstLoad(false);
            console.log("btn-cancel fix");
            document.getElementById("btn-cancel-second").onclick = function (e) { e.preventDefault(); e.stopPropagation(); window.labelSecond.setCoords([]) }
            setReadyForNextMapClick(true);
            enableMenu();
            enable3DBtn();
            Object.entries(layersData.current).forEach(async ([key, value]) => {
                let type = layersData.current[key]["layer type"];
                let layerId = layersData.current[key]["layer_id"].toString();
                if (type === "fill-extrusion") {
                    if (map.getLayer(layerId)) {
                        map.moveLayer(layerId);
                    }
                } else if (type === "symbol") {
                    if (map.getLayer(layerId)) {
                        map.moveLayer(layerId);
                        if (transactionsLayerVisibility.current == 'visible') {
                            moveTransactionLayerTop(map);
                        }
                    }
                } else if (type === "transactions") {
                    moveTransactionLayerTop(map);
                } else if (type === "mivnan_labels") {
                    map.moveLayer('mivnan_labels');
                }

            });
            setReadyForChangeCity(true);
        }
    }
}

/**
 * function to delete source and layer
 * @param {*} map - mapbox map object
 * @param {*} sourceToDelete - source to delete
 * @param {*} prevLayerToDelete - layer to delete
 */
function deleteSourceAndLayer(map, sourceToDelete, prevLayerToDelete) {
    map.removeLayer(prevLayerToDelete);
    map.removeSource(sourceToDelete);
}

/**
 * function that return the draw object to the initial state
 * @param {*} draw - draw object
 * @param {*} setDrawBlock - state setter for draw block
 */
function exitCurrDraw(draw, setDrawBlock) {
    draw.current.deleteAll();
    setDrawBlock(false);
    draw.current.changeMode('simple_select');
    document.getElementsByClassName('calculation-box')[0].innerHTML = '';
    document.getElementsByClassName('calculation-box')[0].style.display = 'none';
}

/**
 * function to move draw layers to the top
 * @param {*} map - mapbox map object
 */
function moveDrawLayerTop(map) {
    map.moveLayer('gl-draw-polygon-fill-active.cold');
    map.moveLayer('gl-draw-polygon-stroke-active.cold');
    map.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.cold');
    map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.cold');
    map.moveLayer('gl-draw-line-active.cold');

    map.moveLayer('gl-draw-polygon-fill-active.hot');
    map.moveLayer('gl-draw-polygon-stroke-active.hot');
    map.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.hot');
    map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.hot');
    map.moveLayer('gl-draw-line-active.hot')

    map.moveLayer('gl-draw-polygon-fill-inactive.cold');
    map.moveLayer('gl-draw-polygon-stroke-inactive.cold');
    map.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.cold');
    map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.cold');
    map.moveLayer('gl-draw-line-inactive.cold');

    map.moveLayer('gl-draw-polygon-fill-inactive.hot');
    map.moveLayer('gl-draw-polygon-stroke-inactive.hot');
    map.moveLayer('gl-draw-line-inactive.hot');
    map.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.hot');
    map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.hot');
}

/**
 * function to move transaction layers to the top
 * @param {*} map - mapbox map object
 */
function moveTransactionLayerTop(map) {
    map.moveLayer("transaction_labels");
    map.moveLayer("transaction_layer");
    map.moveLayer("clusters");
    map.moveLayer("cluster-count");
}
/**
 * function to make transaction layers visible
 * @param {*} map - mapbox map object
 * @param {*} setTransactionsLayerVisibility - state setter for transaction layer visibility
 */
function showTransactionLayer(map, setTransactionsLayerVisibility) {
    setTransactionsLayerVisibility('visible');
    // await add_Transaction_to_map(id);
    map.setLayoutProperty(
        "transaction_labels",
        'visibility',
        'visible'
    );
    map.setLayoutProperty(
        "transaction_layer",
        'visibility',
        'visible'
    );
    map.setLayoutProperty(
        "clusters",
        'visibility',
        'visible'
    );
    map.setLayoutProperty(
        "cluster-count",
        'visibility',
        'visible'
    );
}

/**
 * function to make transaction layers invisible
 * @param {*} map - mapbox map object
 * @param {*} setTransactionsLayerVisibility - state setter for transaction layer visibility
 */
function hideTransactionLayer(map, setTransactionsLayerVisibility) {
    setTransactionsLayerVisibility('none');
    map.setLayoutProperty(
        "transaction_labels",
        'visibility',
        'none'
    );
    map.setLayoutProperty(
        "transaction_layer",
        'visibility',
        'none'
    );
    map.setLayoutProperty(
        "clusters",
        'visibility',
        'none'
    );
    map.setLayoutProperty(
        "cluster-count",
        'visibility',
        'none'
    );
}

/**
 * functon to check if layer is in the BBOX (current map view) and if not to hide it
 * @param {*} map - mapbox map object
 * @param {*} layer - layer to check
 * @param {*} layerState - state of the layer
 * @param {*} parcel_results_layer_id - parcel results layer id
 * @param {*} parcel_to_color_layer_id - parcel to color layer id
 * @param {*} transactions_id - transactions layer id
 * @returns 
 */
function checkAndAdjustLayerInBbox(map, layer, layerState, parcel_results_layer_id, parcel_to_color_layer_id, transactions_id, mivnanimLayerId) {
    let currLayerName = layer;
    if (layer == transactions_id) {
        let intersecting_features = map.queryRenderedFeatures({
            layers: ['transaction_labels', 'cluster-count', 'transaction_layer']
        })
        if (intersecting_features.length == 0) {
            document.getElementById(`${layer}leg`).style.display = 'None';
        } else {
            document.getElementById(`${layer}leg`).style.display = 'block';
        }
        return;
    } else if (layer == mivnanimLayerId) {
        let intersecting_features = map.queryRenderedFeatures({
            layers: ['mivnan_labels']
        })
        if (intersecting_features.length == 0) {
            document.getElementById(`${layer}leg`).style.display = 'None';
        } else {
            document.getElementById(`${layer}leg`).style.display = 'block';
        }
        return;
    }
    if (map.getLayer(layer) == undefined && layerState) {
        currLayerName += "@";
    }
    if (!document.getElementById(`${layer}leg`)) {
        return
    }
    let curr_layer = map.getLayer(currLayerName)
    let layerType = curr_layer.type
    let valToCheck;
    if (layerType == 'fill') {
        valToCheck = 'fill-color';
    } else if (layerType == 'line') {
        valToCheck = 'line-color';
    } else if (layerType == 'fill-extrusion') {
        valToCheck = 'fill-extrusion-color';
    } else if (layerType == 'symbol') {
        valToCheck = 'icon-opacity';
    } else {
        return
    }

    let paint_prop = curr_layer.getPaintProperty(valToCheck)
    // check if the current layer has multicolor
    if (typeof (paint_prop) == 'object' && layerType != 'symbol') {
        //filtering the features according to the map bbox
        let intersecting_features = map.queryRenderedFeatures({
            layers: [currLayerName]
        })

        var propToCheck;
        if (layer == parcel_results_layer_id) {
            propToCheck = 'ischosen';
        } else if (layer == parcel_to_color_layer_id) {
            propToCheck = 'hashid';
        } else {
            propToCheck = 'legend_col';
        }

        //remove duplicates (because of tiles) from intersecting_features
        var unique_intersecting_features = intersecting_features.reduce((unique, o) => {
            if (!unique.some(obj => obj.properties[propToCheck] === o.properties[propToCheck] && obj.value === o.value)) {
                unique.push(o);
            }
            return unique;
        }, []);
        // if the layer dosent exsist at all in the bbox 
        if (unique_intersecting_features.length == 0) {
            document.getElementById(`${layer}leg`).style.display = 'None';
            return
        }
        let color_keys = []
        for (var i = 2; i < paint_prop.length - 1; i++) {
            if (i % 2 === 0) {
                color_keys.push(paint_prop[i]);
            }
        }
        //loop through the keys of colors_dict_object.color (['maine','NewYork'] in this case)
        color_keys.forEach((item) => {
            let filteredIntersctions = unique_intersecting_features.find(obj => obj.properties[propToCheck] == item)
            if (filteredIntersctions == undefined) {
                if (item == '' || item == ' ') {
                    let children_arr = document.getElementById(`${layer}leg`).children
                    for (let index = 0; index < children_arr.length; index++) {
                        let element = children_arr[index];
                        if (element.classList == item) {
                            element.style.display = 'None';
                            break;
                        }
                    }
                } else {
                    if (layer != parcel_results_layer_id && layer != parcel_to_color_layer_id) {
                        document.getElementById(`${layer}leg`).getElementsByClassName(`${item}`)[0].style.display = 'None';
                    } else {
                        document.getElementById(`${layer}leg`).style.display = 'None';
                    }
                }
                return
            }
            let name_features = filteredIntersctions.properties[propToCheck] + ''

            if (name_features.includes(item)) {
                if (layer != parcel_results_layer_id && layer != parcel_to_color_layer_id) {
                    if (item == '') {
                        let children_arr = document.getElementById(`${layer}leg`).children
                        for (let index = 0; index < children_arr.length; index++) {
                            let element = children_arr[index];
                            if (element.classList == '') {
                                element.style.display = 'block';
                                break;
                            }
                        }
                    } else {
                        document.getElementById(`${layer}leg`).getElementsByClassName(`${item}`)[0].style.display = 'block';
                    }
                }
                document.getElementById(`${layer}leg`).style.display = 'block';
            } else {
                if (layer != parcel_results_layer_id && layer != parcel_to_color_layer_id) {
                    if (item == '') {
                        let children_arr = document.getElementById(`${layer}leg`).children
                        for (let index = 0; index < children_arr.length; index++) {
                            let element = children_arr[index];
                            if (element.classList == '') {
                                element.style.display = 'None';
                                break;
                            }
                        }
                    } else {
                        document.getElementById(`${layer}leg`).getElementsByClassName(`${item}`)[0].style.display = 'None';
                    }
                } else {
                    document.getElementById(`${layer}leg`).style.display = 'None';
                }
            }
        })
    }
    // if its another type than 'object'
    else {
        //filtering the features according to the map bbox
        let intersecting_features = map.queryRenderedFeatures({
            layers: [currLayerName]
        })
        if (intersecting_features.length == 0) {
            document.getElementById(`${layer}leg`).style.display = 'None';
        } else {
            document.getElementById(`${layer}leg`).style.display = 'block';
        }
    }
}

/**
 * wrapper function for checkAndAdjustLayerInBbox that go over all the active layers and check if they are in the bbox
 * @param {*} map - the map object
 * @param {*} layerState - the state of the layers
 * @param {*} parcel_results_layer_id - the id of the parcel results layer
 * @param {*} parcel_to_color_layer_id - the id of the parcel to color layer (click on map)
 * @param {*} building_to_color_layer_id - the id of the building to color layer (click on map)
 * @param {*} transactions_id  - the id of the transactions layer
 * @param {*} mivnanimLayerId - the id of the mivnanim layer
 */
function adjustDynamicLegend(map, layerState, parcel_results_layer_id, parcel_to_color_layer_id, building_to_color_layer_id, transactions_id, mivnanimLayerId) {
    let items = document.querySelectorAll('#menu a.active');

    for (var i = 0; i < items.length; i++) {
        let layer = items[i].id;
        checkAndAdjustLayerInBbox(map, layer, layerState, parcel_results_layer_id, parcel_to_color_layer_id, transactions_id, mivnanimLayerId);
    }
    if (document.getElementById(`${parcel_to_color_layer_id}leg`)) {
        checkAndAdjustLayerInBbox(map, parcel_to_color_layer_id, layerState, parcel_results_layer_id, parcel_to_color_layer_id, transactions_id, mivnanimLayerId);
    }
    if (document.getElementById(`${building_to_color_layer_id}leg`)) {
        checkAndAdjustLayerInBbox(map, building_to_color_layer_id, layerState, parcel_results_layer_id, parcel_to_color_layer_id, transactions_id, mivnanimLayerId);
    }
}

/**
 * map onClick event handler
 * @param {*} map - the map object
 * @param {*} e - the event object
 * @param {*} city - the city name
 * @param {*} countryName - the country name
 * @param {*} draw - the draw object
 * @param {*} drawBlock - the draw block state flag
 * @param {*} layerState - the state of the layers
 * @param {*} setReadyForChangeCity - the setter for state flag for ready for change city
 * @param {*} layersData - the layers data
 * @param {*} updateData - the state update data function
 * @param {*} spacialLayersId  - the id of the spacial layers
 * @param {*} clickOnRes - the state flag for click on res
 * @param {*} setClickOnRes - the setter for state flag for click on res
 * @param {*} setClickOnBoth - the setter for state flag for click on buildings in result parcel and not a result parcel
 * @param {*} addBuildingsInBoth - the setter for state flag for click on buildings in result parcel and not a result parcel
 * @param {*} popUp3D - the state flag for pop up 3D
 * @param {*} chosenLang - the chosen language
 * @param {*} lastPaintData - the last paint data
 * @param {*} updateLastPaintData - the state update last paint data function
 * @param {*} resultsArr - the results array
 * @param {*} addResultsArr - the setter for add results array
 * @param {*} removeResultsArr - the setter for remove results array
 * @param {*} clickOnBoth - the state flag for click on buildings in result parcel and not a result parcel
 * @param {*} buildingsInBoth - the state arr for click on buildings in result parcel and not a result parcel
 * @param {*} setReadyToIdle - the setter for state flag for ready to idle
 * @param {*} setNeedToDelete - the setter for state flag for need to delete
 * @param {*} fillLayers - the fill layers array
 * @param {*} resultCenter - the result center
 * @param {*} tilesDomainName - the tiles server domain name
 * @param {*} threeDFlag - the 3D flag
 * @param {*} layersDataAndColors - the layers data and colors
 * @param {*} firstPaintOccur - the first paint occur flag
 * @param {*} setFirstPaintOccur - the setter for first paint occur flag
 * @param {*} needToDelete - the need to delete flag
 * @param {*} readyForNextMapClick - the ready for next map click flag
 * @param {*} setReadyForNextMapClick - the setter for ready for next map click flag
 * @param {*} transactionsLayerVisibility - the transactions layer visibility flag
 * @returns 
 */
async function mapOnClickHandler(map, e, city, countryName, draw, drawBlock, layerState, setReadyForChangeCity, layersData, updateData, spacialLayersId, clickOnRes, setClickOnRes, setClickOnBoth, addBuildingsInBoth, popUp3D, chosenLang, lastPaintData, updateLastPaintData, resultsArr, addResultsArr, removeResultsArr, clickOnBoth, buildingsInBoth, setReadyToIdle, setNeedToDelete, fillLayers, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, firstPaintOccur, setFirstPaintOccur, needToDelete, readyForNextMapClick, setReadyForNextMapClick, transactionsLayerVisibility) {
    let curr_draw_mode = draw.current.getMode();
    console.log(curr_draw_mode);
    if (curr_draw_mode == 'draw_polygon' || curr_draw_mode == 'draw_line_string' || (curr_draw_mode == 'simple_select' && drawBlock.current)) {
        return;
    }

    let features = map.queryRenderedFeatures(e.point);
    let extractedLayers = []
    let currentClickedLayer = []
    //remove mapbox tile error duplication
    features.forEach(item => {
        if (item.id == undefined) {
            if (currentClickedLayer.indexOf(item.layer.id) === -1) {
                extractedLayers.push(item);
                currentClickedLayer.push(item.layer.id);
            }
        }
    })
    if (currentClickedLayer.length != 0) {

        let Top_click_Layer_Name = currentClickedLayer[0];
        let topTableName = "";
        Top_click_Layer_Name = Top_click_Layer_Name.toLowerCase();
        if (Top_click_Layer_Name.includes("parcel")) {
            topTableName = "parcel"
        } else {
            if (layerState.current) {
                Top_click_Layer_Name = Top_click_Layer_Name.substring(0, Top_click_Layer_Name.length - 1);
            }
            if (Top_click_Layer_Name.includes("results")) {
                topTableName = Top_click_Layer_Name.replace(" results", "");
                topTableName = topTableName.replace(/ /g, '_');
            } else {
                topTableName = Top_click_Layer_Name.replace(" rest of the city", "");
                topTableName = topTableName.replace(/ /g, '_');
            }
        }
        if (Top_click_Layer_Name == 'transaction_labels' || Top_click_Layer_Name == 'transaction_layer') {
            // console.log("click on label");
        } else {
            await getAndPaintBuildingAndParcelFromLanLat(map, countryName, city, e.lngLat, needToDelete, readyForNextMapClick, setReadyForNextMapClick, setReadyForChangeCity, layersData, updateData, spacialLayersId, clickOnRes, setClickOnRes, setClickOnBoth, addBuildingsInBoth, popUp3D, chosenLang, layerState, lastPaintData, updateLastPaintData, resultsArr, addResultsArr, removeResultsArr, clickOnBoth, buildingsInBoth, setReadyToIdle, setNeedToDelete, fillLayers, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, firstPaintOccur, setFirstPaintOccur, transactionsLayerVisibility);
        }

        let clickObj
        clickObj = {
            "Click_location": e.lngLat,
            "Top_click_Layer_Name": Top_click_Layer_Name,
            "Click_Layers": currentClickedLayer,
            "Click_Layers_objects": extractedLayers
        }
        console.log(clickObj);
    } else {
        console.log({ "Click_location": e.lngLat });
    }
}

/**
 * function to create the 3d label
 * @param {*} building_to_color_layer_id - the building to color layer id
 * @param {*} lastPaintData - the last paint data
 * @returns 
 */
function createLabelSec(building_to_color_layer_id, lastPaintData) {
    let divToolTip;
    let divContent = document.createElement('div');
    divContent.className = 'mapboxgl-popup-content-3D';
    divContent.style.width = "fit-content";
    var cancelButton = document.createElement('button');
    cancelButton.id = "btn-cancel-second"
    cancelButton.className = "mapboxgl-popup-close-button";

    cancelButton.innerHTML = "×"
    cancelButton.type = "button"
    divContent.appendChild(cancelButton);
    let strong = document.createElement('strong');
    strong.id = "idForSetSecond"
    document.getElementById(`${building_to_color_layer_id}leg`)
    // if (document.querySelector(`#${building_to_color_layer_id}leg`).style.display == 'block') {
    //     strong.innerHTML = lastPaintData.current["text"];
    // }
    strong.innerHTML = lastPaintData.current["text"];
    // strong.innerHTML = 'sdfsfsdfd';
    divContent.appendChild(strong);
    let tip = document.createElement('div');
    tip.className = 'mapboxgl-popup-tip-3D';
    let div = document.createElement('div');
    div.className = 'marker mapboxgl-popup-anchor-bottom';
    div.appendChild(tip);
    div.appendChild(divContent);
    divToolTip = document.createElement('div');
    divToolTip.className += 'label3D';
    divToolTip.id = "3d-toolTip-sec";
    divToolTip.appendChild(div);
    divToolTip.style.display = 'block';
    return divToolTip;
}

/**
 * function to init the three box object
 * @param {*} map - the map object
 * @param {*} building_to_color_layer_id - the building to color layer id
 * @param {*} lastPaintData - the last paint data
 * @param {*} setPopUp3D - the set pop up 3d function
 * @param {*} popUp3D - the pop up 3d object
 */
function initThreeBox(map, building_to_color_layer_id, lastPaintData, setPopUp3D, popUp3D) {
    let obj;
    // let labelSecond;
    map.addLayer({
        id: 'custom_layer',
        type: 'custom',
        renderingMode: '3d',
        layout: {
            visibility: 'visible'
        },
        onAdd: function (map, mbxContext) {
            window.tb = new Threebox(
                map,
                mbxContext, //get the context from the map canvas
                {
                    defaultLights: true,
                    enableSelectingFeatures: true, //change this to false to disable fill-extrusion features selection
                    enableSelectingObjects: true, //change this to false to disable 3D objects selection
                    enableTooltips: true, // change this to false to disable default tooltips on fill-extrusion and 3D models
                }
            );
            window.labelSecond = window.tb.label(obj = {
                position: [],
                htmlElement: createLabelSec(building_to_color_layer_id, lastPaintData),
                cssClass: "label3D",
                alwaysVisible: true,
                bottomMargin: 0,
                feature: null
            })
            // 
            // setPopUp3D(labelSecond);

            window.labelSecond.setCoords([]);
            window.tb.add(window.labelSecond);

        },

        render: function (gl, matrix) {
            window.tb.update();
        }
    });
}

/**
 * function to get the building and parcel from lan lat and paint them
 * @param {*} map - the map object
 * @param {*} countryName - the country name
 * @param {*} city - the city name
 * @param {*} lanLat - the lan lat object
 * @param {*} needToDelete - the need to delete flag
 * @param {*} readyForNextMapClick - the ready for next map click flag
 * @param {*} setReadyForNextMapClick - the set ready for next map click function
 * @param {*} setReadyForChangeCity - the set ready for change city function
 * @param {*} layersData - the layers data
 * @param {*} updateData - the update data function
 * @param {*} spacialLayersId - the spacial layers id
 * @param {*} clickOnRes - the click on res flag
 * @param {*} setClickOnRes - the set click on res function
 * @param {*} setClickOnBoth - the set click on both function
 * @param {*} addBuildingsInBoth - the add buildings in both function
 * @param {*} popUp3D - the pop up 3d object
 * @param {*} chosenLang - the chosen lang
 * @param {*} layerState - the layer state
 * @param {*} lastPaintData -   the last paint data
 * @param {*} updateLastPaintData - the update last paint data function
 * @param {*} resultsArr - the results array
 * @param {*} addResultsArr - the add results array function
 * @param {*} removeResultsArr - the remove results array function
 * @param {*} clickOnBoth - the click on both flag
 * @param {*} buildingsInBoth - the buildings in both array
 * @param {*} setReadyToIdle - the set ready to idle function
 * @param {*} setNeedToDelete - the set need to delete function
 * @param {*} fillLayers - the fill layers array
 * @param {*} resultCenter - the result center object
 * @param {*} tilesDomainName - the tile server domain name
 * @param {*} threeDFlag - the 3d flag
 * @param {*} layersDataAndColors - the layers data and colors
 * @param {*} firstPaintOccur - the first paint occur flag
 * @param {*} setFirstPaintOccur - the set first paint occur function
 * @param {*} transactionsLayerVisibility - the transactions layer visibility flag
 * @returns 
 */
async function getAndPaintBuildingAndParcelFromLanLat(map, countryName, city, lanLat, needToDelete, readyForNextMapClick, setReadyForNextMapClick, setReadyForChangeCity, layersData, updateData, spacialLayersId, clickOnRes, setClickOnRes, setClickOnBoth, addBuildingsInBoth, popUp3D, chosenLang, layerState, lastPaintData, updateLastPaintData, resultsArr, addResultsArr, removeResultsArr, clickOnBoth, buildingsInBoth, setReadyToIdle, setNeedToDelete, fillLayers, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, firstPaintOccur, setFirstPaintOccur, transactionsLayerVisibility) {
    if (!needToDelete.current) {
        if (readyForNextMapClick.current) {
            // marker2.remove();
            setReadyForNextMapClick(false);
            setReadyForChangeCity(false);
            if (typeof lanLat === String) {
                lanLat = JSON.parse(lanLat)
            }

            var BuildingAndParcelToPaint_response = await getParcelBuildings(countryName, city, lanLat["lng"], lanLat["lat"]);

            if (BuildingAndParcelToPaint_response["parcelsIdToPaint"].length === 0 && BuildingAndParcelToPaint_response["buildingsIdToPaint"].length === 0) {
                setReadyForNextMapClick(true);
                setReadyForNextMapClick(true);
                return;
            }
            for (let building in BuildingAndParcelToPaint_response["buildingsIdToPaint"]) {
                building = BuildingAndParcelToPaint_response["buildingsIdToPaint"][building]
                let index = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']]["results"].indexOf(building)
                if (index != -1) {
                    if (BuildingAndParcelToPaint_response["parcelsIdToPaint"][0] == layersData.current["layer" + spacialLayersId.current['parcel_results']]["results"][0]) {
                        setClickOnRes(true);
                        break;
                    } else {
                        addBuildingsInBoth(building);
                        setClickOnBoth(true);
                    }
                } else {
                    setClickOnRes(false);
                    // clickOnBothSecMap = false;
                }
            }

            window.labelSecond.setCoords([]);
            let intArr = [];
            for (let element in BuildingAndParcelToPaint_response["buildingsIdToPaint"]) {
                element = parseInt(BuildingAndParcelToPaint_response["buildingsIdToPaint"][element])
                intArr.push(element)
                let index = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']]["results"].indexOf(element);
                if (index != -1) {
                    // updateData
                    layersData.current["layer" + spacialLayersId.current['building_res_layer_id']]["results"].splice(index, 1)
                }
            }
            flyToClick(map, lanLat, threeDFlag);
            await paintParcelAndBuilding(map, BuildingAndParcelToPaint_response["parcelsIdToPaint"], BuildingAndParcelToPaint_response["buildingsIdToPaint"], clickOnRes, lastPaintData, updateLastPaintData, layersData, updateData, spacialLayersId, resultsArr, addResultsArr, removeResultsArr, clickOnBoth, buildingsInBoth, setReadyToIdle, setNeedToDelete, fillLayers, layerState, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, firstPaintOccur, setFirstPaintOccur, transactionsLayerVisibility);

            let height = BuildingAndParcelToPaint_response["z-height"];
            let inHtml = createTextForPopUp(BuildingAndParcelToPaint_response["gush"], BuildingAndParcelToPaint_response["helka"], BuildingAndParcelToPaint_response["full_url"], countryName, chosenLang);
            window.labelSecond.setCoords([lanLat["lng"], lanLat["lat"], height]);
            updateLastPaintData("text", inHtml);
            updateLastPaintData("loc", [lanLat["lng"], lanLat["lat"], height]);
            window.tb.update();
            document.getElementById('idForSetSecond').innerHTML = inHtml;
            document.querySelector("#idForSetSecond a").onclick = function (e) { e.preventDefault(); e.stopPropagation(); window.location.href = this.dataset.href; setReadyForNextMapClick(true); }
            let building_layer_name = spacialLayersId.current['building_res_layer_id'];
            if (layerState.current) {
                building_layer_name += "@"
            }
            map.setFilter(building_layer_name, ["!in", "id", ...intArr]);
            // check if needed both line below
            // lastPaintResultSecMap = clickOnRes;
            // hasBothBuildingSecMap = clickOnBothSecMap;
            setClickOnBoth(false);
        }
    }
}

/**
 * function that paint the parcel and building on the map
 * @param {*} map - the map object
 * @param {*} parcelToColorArr - the parcel to color array
 * @param {*} buildingsToColorArr - the buildings to color array
 * @param {*} clickOnRes - the click on res flag
 * @param {*} lastPaintData - the last paint data
 * @param {*} updateLastPaintData - the update last paint data function
 * @param {*} layersData - the layers data
 * @param {*} updateData - the update data function
 * @param {*} spacialLayersId - the spacial layers id
 * @param {*} resultsArr - the results array
 * @param {*} addResultsArr - the add results array
 * @param {*} removeResultsArr - the remove results array
 * @param {*} clickOnBoth - the click on both flag
 * @param {*} buildingsInBoth - the buildings in both array
 * @param {*} setReadyToIdle - the set ready to idle function
 * @param {*} setNeedToDelete - the set need to delete function
 * @param {*} fillLayers - the fill layers array
 * @param {*} layerState - the layer state
 * @param {*} resultCenter - the result center
 * @param {*} tilesDomainName - the tiles server domain name
 * @param {*} threeDFlag - the 2d/3d flag
 * @param {*} layersDataAndColors - the layers data and colors
 * @param {*} firstPaintOccur - the first paint occur flag
 * @param {*} setFirstPaintOccur - the set first paint occur function
 * @param {*} transactionsLayerVisibility - the transactions layer visibility flag
 */
async function paintParcelAndBuilding(map, parcelToColorArr, buildingsToColorArr, clickOnRes, lastPaintData, updateLastPaintData, layersData, updateData, spacialLayersId, resultsArr, addResultsArr, removeResultsArr, clickOnBoth, buildingsInBoth, setReadyToIdle, setNeedToDelete, fillLayers, layerState, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, firstPaintOccur, setFirstPaintOccur, transactionsLayerVisibility) {
    // resultsArr,addResultsArr, removeResultsArr
    disableMenu();
    if (clickOnRes.current) {
        //enter the card res back to the res layer
        for (let element in lastPaintData.current['prevColoredBuildings']) {
            element = parseInt(lastPaintData.current['prevColoredBuildings'][element])

            let curr_layersData_obj = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']];
            curr_layersData_obj["results"].push(element);
            // DATA1["layer" + building_res_layer_id]["results"].push(element)

            let index = resultsArr.current.indexOf(element);
            if (index === -1) {
                addResultsArr(element);
            }
        }
        updateData("layer" + spacialLayersId.current['building_res_layer_id'], curr_layersData_obj);
    }
    if (clickOnBoth.current) {
        let curr_layersData_obj = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']];
        curr_layersData_obj["results"] = curr_layersData_obj["results"].concat(buildingsInBoth.current);
        updateData("layer" + spacialLayersId.current['building_res_layer_id'], curr_layersData_obj);
        // DATA1["layer" + building_res_layer_id]["results"] = DATA1["layer" + building_res_layer_id]["results"].concat(buildingsInBothSecMap);
    }
    //remove the previous from restArr
    if (lastPaintData.current['prevColoredBuildings'].length != 0) {
        for (let i = 0; i < lastPaintData.current['prevColoredBuildings'].length; i++) {
            let index = resultsArr.current.indexOf(lastPaintData.current['prevColoredBuildings'][i]);
            if (index != -1) {
                let resIndex = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']]["results"].indexOf(lastPaintData.current['prevColoredBuildings'][i])
                let bothIndex = buildingsInBoth.current.indexOf(lastPaintData.current['prevColoredBuildings'][i])
                if (resIndex === -1 && bothIndex === -1) {
                    // restOfCityArrSecMap.splice(index, 1);
                    removeResultsArr(lastPaintData.current['prevColoredBuildings'][i]);
                }
            }
        }
        updateLastPaintData('prevColoredBuildings', []);
    }

    //enter the new buildings to the restArr and prevArr
    for (let i = 0; i < buildingsToColorArr.length; i++) {
        if (resultsArr.current.indexOf(buildingsToColorArr[i]) === -1) {
            addResultsArr(buildingsToColorArr[i]);
            // prevColoredBuildingsSecMap.push(buildingsToColorArr[i])
        }
    }
    updateLastPaintData('prevColoredBuildings', buildingsToColorArr);
    // layersData, updateData
    let curr_layersData_obj = layersData.current["layer" + spacialLayersId.current['building_to_color_layer_id']];
    curr_layersData_obj["results"] = buildingsToColorArr;
    updateData("layer" + spacialLayersId.current['building_to_color_layer_id'], curr_layersData_obj);
    // DATA1["layer" + building_to_color_layer_id]["results"] = buildingsToColorArr;

    await reload3DLayers(map, undefined, setReadyToIdle, setNeedToDelete, fillLayers, layersData, layerState, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId.current['parcel_to_color_layer_id'], resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, transactionsLayerVisibility);
    if (parcelToColorArr[0]) {

        let layerData = layersDataAndColors.current.filter(function (item) { return item.id == spacialLayersId.current['parcel_to_color_layer_id']; });
        let res = layerData[0]

        if (typeof parcelToColorArr[0] == 'number') {
            map.setPaintProperty(spacialLayersId.current['parcel_to_color_layer_id'], 'fill-color',
                ['match', ['number', ['get', 'hashid']], parseInt(parcelToColorArr[0]), res["color"], 'rgba(0,0,0,0)']
            );
            map.setPaintProperty(spacialLayersId.current['parcel_to_color_layer_id'], 'fill-outline-color',
                ['match', ['number', ['get', 'hashid']], parseInt(parcelToColorArr[0]), 'rgba(0,0,0,1)', 'rgba(0,0,0,0)']
            );
        } else {
            map.setPaintProperty(spacialLayersId.current['parcel_to_color_layer_id'], 'fill-color',
                ['match', ['string', ['get', 'hashid']], String(parcelToColorArr[0]), res["color"], 'rgba(0,0,0,0)']
            );
            map.setPaintProperty(spacialLayersId.current['parcel_to_color_layer_id'], 'fill-outline-color',
                ['match', ['string', ['get', 'hashid']], String(parcelToColorArr[0]), 'rgba(0,0,0,1)', 'rgba(0,0,0,0)']
            );
        }

    }

    if (!firstPaintOccur.current) {
        setFirstPaintOccur(true);
        await updateLegend(map, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId);
        await updateLegend(map, spacialLayersId.current['parcel_to_color_layer_id'], spacialLayersId);
    }
    enableMenu()


}

/**
 * function that creates the text for the popup of the clicked parcel and building
 * @param {*} gush - the gush of the parcel
 * @param {*} helka - the helka of the parcel
 * @param {*} full_url - the url of the information page to set
 * @param {*} countryName - the name of the country
 * @param {*} chosenLang - the chosen language
 * @returns the text for the popup
 */
function createTextForPopUp(gush, helka, full_url, countryName, chosenLang) {
    switch (countryName) {
        case "Israel":
            switch (chosenLang.current) {
                case "he":
                    return '<div class="popup">גוש ' + gush + ' | חלקה ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">דף מידע</div>';
                case "en":
                    return '<div class="popup">block ' + gush + ' | Parcel ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">information page</div>';
                case "es":
                    return '<div class="popup">Referencia Catastral ' + gush + ' | Referencia Catastral ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">Página informativa</div>';
                case "de":
                    return '<div class="popup">Katasteramtliche Referenz ' + gush + ' | Katasteramtliche Referenz ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">Página informativa</div>';
                default:
                    return ''
            }
        case "Spain":
            switch (chosenLang.current) {
                case "he":
                    return '<div class="popup">חלקה ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">דף מידע</div>';
                case "en":
                    return '<div class="popup">Parcel ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">information page</div>';
                case "es":
                    return '<div class="popup">Referencia Catastral ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">Página informativa</div>';
                case "de":
                    return '<div class="popup">Katasteramtliche Referenz ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">Informationsseite</div>';
                default:
                    return ''
            }
        case "Germany":
            switch (chosenLang.current) {
                case "he":
                    return '<div class="popup">חלקה ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">דף מידע</div>';
                case "en":
                    return '<div class="popup">Parcel ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">information page</div>';
                case "es":
                    return '<div class="popup">Referencia Catastral ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">Página informativa</div>';
                case "de":
                    return '<div class="popup">Katasteramtliche Referenz ' + helka + '</div><a data-href="' + full_url + '"><div class="infobutton" style="cursor: pointer;">Informationsseite</div>';
                default:
                    return ''
            }
        default:
            return ''
    }
}

/**
 * function that reloads the map after changing base map
 * @param {*} map - the mapbox object
 * @param {*} setActiveLayers - the function that sets the active layers
 * @param {*} firstLoad - flag that indicates if it is the first load
 * @param {*} setFirstLoad - setter function for the first load flag
 * @param {*} setNeedToDelete - setter function for the need to delete flag
 * @param {*} layerState - flag that indicates if the layers state
 * @param {*} setLayerState - setter function for the layers state flag
 * @param {*} setReadyForNextMapClick - setter function for the ready for next map click flag
 * @param {*} setReadyForChangeCity - setter function for the ready for change city flag
 * @param {*} layersData - the layers data
 * @param {*} layersDataAndColors - the layers data and colors
 * @param {*} spacialLayersId - the spacial layers id
 * @param {*} lastPaintData - the last paint data
 * @param {*} resultCenter - the result center
 * @param {*} tilesDomainName - the tiles domain name
 * @param {*} threeDFlag - rhe 3d flag
 * @param {*} setFinishToLoadLayers = setter function for the finish to load layers flag
 * @param {*} layers - the layers array
 * @param {*} resultsArr - the results array
 * @param {*} setResultsArr - setter function for the results array
 */
async function reloadMap(map, setActiveLayers, firstLoad, setFirstLoad, setNeedToDelete, layerState, setLayerState, setReadyForNextMapClick, setReadyForChangeCity, layersData, layersDataAndColors, spacialLayersId, lastPaintData, resultCenter, tilesDomainName, threeDFlag, setFinishToLoadLayers, layers, resultsArr, setResultsArr) {
    setActiveLayers([]);
    setFirstLoad(true);

    setNeedToDelete(false);
    setLayerState(false);

    setReadyForNextMapClick(false);
    document.getElementById("btn-cancel-second").click();
    document.getElementById("3d-toolTip-sec").remove();
    setReadyForChangeCity(false);
    // paintBuildingsLoc = {};

    let [sourceArr, resultsArrToSave] = await initLayersToLoad(layersData, layersDataAndColors);
    setResultsArr(resultsArrToSave);
    let [layers3D, fillLayers, activeLayers] = await loadLayers(map, sourceArr, -1, firstLoad, layerState, layersData, true, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId.current['parcel_to_color_layer_id'], resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors);
    setFinishToLoadLayers(true);
    setActiveLayers(activeLayers);
    map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
    });
    // add the DEM source as a terrain layer with exaggerated height
    map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
    initThreeBox(map, spacialLayersId.current['building_to_color_layer_id'], lastPaintData, undefined, undefined)

    window.labelSecond.setCoords(lastPaintData.current["loc"]);
    // if ($(`#${building_to_color_layer_id}leg1`).length !== 0) {
    // }
}

/**
 * function to filter layer according to table filter
 * @param {*} map - the mapbox object
 * @param {*} layerId - the layer id
 * @param {*} values - the values to filter 
 * @param {*} addPrevFilteredLayers - the function that adds the previous filtered layers
 * @returns 
 */
function filterLayerArr(map, layerId, values, addPrevFilteredLayers) {

    let layerType = map.getLayer(layerId).type
    let visibility = map.getLayoutProperty(
        layerId,
        'visibility'
    );

    if (visibility == 'none') {
        document.getElementById(`${layerId}`).click();
    }
    let allowedLayersTypes = ['fill', 'symbol', 'line']
    if (!allowedLayersTypes.includes(layerType)) {
        return;
    }
    map.setFilter(
        layerId,
        ['match', ['get', 'snap_id'], values, true, false]
    );
    addPrevFilteredLayers(layerId, values);
}

/**
 * function to clear filter layer from table filter
 * @param {*} map - the mapbox object
 * @param {*} layerId - the layer id
 * @param {*} removePrevFilteredLayers  - the function that removes the previous filtered layers
 * @param {*} interValsObj - the intervals object
 * @param {*} clearTimerObj - the clear timer object
 * @param {*} removeInterValsObj - the function that removes the intervals object
 * @param {*} removeClearTimerObj - the function that removes the clear timer object
 * @returns 
 */
function clearFilterLayerArr(map, layerId, removePrevFilteredLayers, interValsObj, clearTimerObj, removeInterValsObj, removeClearTimerObj) {
    let layerType = map.getLayer(layerId).type;
    let propertyToSet = '';
    let valueToSet;
    if (layerType == 'fill') {
        propertyToSet = 'fill-opacity';
        valueToSet = 0.5
    } else if (layerType == 'symbol') {
        propertyToSet = 'icon-opacity';
        valueToSet = 1
    } else if (layerType == 'line') {
        propertyToSet = 'line-opacity';
        valueToSet = 0.8;
    } else {
        return;
    }
    clearFocusFeature(layerId, interValsObj, clearTimerObj, removeInterValsObj, removeClearTimerObj);
    // init opacity to initial state before focuses
    map.setPaintProperty(
        layerId,
        propertyToSet,
        valueToSet
    );
    // clear the layer prev filter
    map.setFilter(
        layerId
    );
    // double check of init opacity because the focus timeout
    setTimeout(() => {
        map.setPaintProperty(
            layerId,
            propertyToSet,
            valueToSet);
    }, 501);
    removePrevFilteredLayers(layerId);
}

/**
 * function to focus feature and make him blink
 * @param {*} map - the mapbox object
 * @param {*} layerId - the layer id
 * @param {*} id - the feature id
 * @param {*} prevFilteredLayers - the previous filtered layers
 * @param {*} updatePrevFilteredLayers - the function that updates the previous filtered layers
 * @param {*} interValsObj - the intervals object
 * @param {*} addInterValsObj - the function that adds the intervals object
 * @param {*} removeInterValsObj - the function that removes the intervals object
 * @param {*} clearTimerObj - the clear timer object
 * @param {*} addClearTimerObj - the function that adds the clear timer object
 * @param {*} removeClearTimerObj - the function that removes the clear timer object
 * @param {*} countryName - the country name
 * @param {*} removePrevFilteredLayers - the function that removes the previous filtered layers
 * @returns 
 */
async function focusFeature(map, layerId, id, prevFilteredLayers, updatePrevFilteredLayers, interValsObj, addInterValsObj, removeInterValsObj, clearTimerObj, addClearTimerObj, removeClearTimerObj, countryName, removePrevFilteredLayers) {
    clearFocusFeature(layerId, interValsObj, clearTimerObj, removeInterValsObj, removeClearTimerObj);
    let visibility = map.getLayoutProperty(
        layerId,
        'visibility'
    );
    let layerType = map.getLayer(layerId).type;
    let propertyToSet = '';
    let additionalPadding = 0;
    if (layerType == 'fill') {
        propertyToSet = 'fill-opacity';
    } else if (layerType == 'symbol') {
        additionalPadding = 50;
        propertyToSet = 'icon-opacity';
    } else if (layerType == 'line') {
        propertyToSet = 'line-opacity';
    } else {
        return;
    }

    if (visibility == 'none') {
        document.getElementById(`${layerId}`).click();
    }
    var res = await getFeatureBox(countryName, layerId, id);
    let paddingToSet = 20 + additionalPadding;
    let option = {
        speed: 0.6,
        curve: 1,
        pitch: 0,
        padding: { top: paddingToSet, bottom: paddingToSet, left: paddingToSet, right: paddingToSet }
    }
    if (layerType == 'symbol') {
        option['maxZoom'] = 20;
        option['pitch'] = 20;
    }
    map.fitBounds(res, option);
    // set the focus blink
    if (prevFilteredLayers.current[layerId]) {
        let index = prevFilteredLayers.current[layerId].indexOf(id);
        let temp_arr = prevFilteredLayers.current[layerId];
        let temp_arr_to_slice = prevFilteredLayers.current[layerId];
        if (index !== -1) {
            // update
            temp_arr_to_slice.splice(index, 1);
            updatePrevFilteredLayers(layerId, temp_arr_to_slice);
        }
        console.log(`map.setPaintProperty(
            ${layerId},
            ${propertyToSet},
            ['match', ['get', 'snap_id'], ${id}, 0.8, ${prevFilteredLayers.current[layerId]}, 0.7, 0]
        )`);
        addInterValsObj(layerId, setInterval(function () {
            map.setPaintProperty(
                layerId,
                propertyToSet,
                ['match', ['get', 'snap_id'], id, 0.8, prevFilteredLayers.current[layerId], 0.7, 0]
            );
            setTimeout(() => {
                map.setPaintProperty(
                    layerId,
                    propertyToSet,
                    ['match', ['get', 'snap_id'], temp_arr, 0.7, id, 0.7, 0]
                )
                // map.setPaintProperty(
                // layerId, 
                // propertyToSet, 
                // 0);
            }, 500);

        }, 1000));
    } else {
        addInterValsObj(layerId, setInterval(function () {
            map.setPaintProperty(
                layerId,
                propertyToSet,
                ['match', ['get', 'snap_id'], id, 0.8, 0]
            );
            setTimeout(() => {
                map.setPaintProperty(
                    layerId,
                    propertyToSet,
                    0);
            }, 500);

        }, 1000));
    }
    // set timeout to clear the focus blink
    addClearTimerObj(layerId, setTimeout(() => {
        // filterLayerArr(layerId,prevFilteredLayers[layerId]);
        // clearFilterLayerArr(layerId);
        clearFilterLayerArr(map, layerId, removePrevFilteredLayers, interValsObj, clearTimerObj, removeInterValsObj, removeClearTimerObj);
        // $("i.fa-thin.fa-crosshairs-simple").css({ "color": "black" })
    }, 10000))
}

/**
 * function to clear the focus feature
 * @param {*} layerId - the layer id
 * @param {*} interValsObj - the intervals object
 * @param {*} clearTimerObj - the clear timer object
 * @param {*} removeInterValsObj - the function that removes the intervals object
 * @param {*} removeClearTimerObj - the function that removes the clear timer object
 */
function clearFocusFeature(layerId, interValsObj, clearTimerObj, removeInterValsObj, removeClearTimerObj) {
    clearInterval(interValsObj.current[layerId]);
    clearTimeout(clearTimerObj.current[layerId]);
    removeInterValsObj(layerId);
    removeClearTimerObj(layerId);
}

async function add_mivnan_labels(map, geojson, mivnanimLayerId, layersDataAndColors) {
    let res = layersDataAndColors.current.filter(function (item) { return item.id == mivnanimLayerId; });
    res = res[0];
    let visibility = res["visible_on_first_load"] ? 'visible' : 'none';
    map.addSource('mivnan_label_src', {
        'type': 'geojson', 'data': geojson,
    });

    map.loadImage(
        res['color'],
        (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker2', image);
            // // Add a GeoJSON source with 2 points
            // map.addSource('points', {
            //     'type': 'geojson',
            //     'data': geojson_to_add
            // })
        }
    );

    // Add a symbol layer
    map.addLayer({
        'id': 'mivnan_labels',
        'type': 'symbol',
        'source': 'mivnan_label_src',
        'layout': {
            'icon-image': 'custom-marker2',
            // get the title name from the source's "title" property
            'text-field': ['get', 'mivnan_num'],
            'icon-anchor': 'bottom',
            'text-anchor': 'bottom',
            'text-offset': [0, -0.75],
            'visibility': visibility,
            'icon-allow-overlap': true,
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [5, 10, 10, 10],
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 12,
            'icon-size': 1
        }
        ,
        'paint': {
            'text-color': 'rgb(0,0,0)'
        }
    });
    // map.setLayerZoomRange('mivnan_labels', 16, 22);

}

async function changeCity(map, cityId, layers3D, fillLayers, layerState, readyForChangeCity, countryName, setLayersData, layersData, seCity, setSpacialLayersId, setLayers, setLayers3D, setFillLayer, setResultsArr, setFirstLoad, setNeedToDelete, setThreeDflag, setLayerState, setFirstPaintOccur, setReadyToIdle, setLastPaintData, setReadyForNextMapClick, setClickOnRes, setClickOnBoth, setBuildingsInBoth, setFinishToLoadLayers, setActiveLayers, addLayer, setLayersDataAndColors, layers, setResultCenter, resultCenter, chosenLang, layersDataAndColors, firstLoad, spacialLayersId, resultsArr, tilesDomainName, threeDFlag, setTransactionsId, advance_search_data, transaction_popup, setMivnanimLayerId, mivnanim_data, transactionsId, dynamicLegendInterval) {
    if (!readyForChangeCity.current) {
        return;
    }
    clearInterval(dynamicLegendInterval.current);
    //delete previous layers and sources
    layers3D.current.forEach(layer => {
        let prevSourceName = "layer" + layer + "_source";
        let prevLayer = layer;

        if (layerState.current) {
            prevSourceName += "@";
            prevLayer += "@";
        }
        deleteSourceAndLayer(map, prevSourceName, prevLayer);
    });
    fillLayers.current.forEach(layer => {
        if (transactionsId.current == layer) {
            return;
        }
        let prevSourceName = "layer" + layer + "_source";
        let prevLayer = layer;
        deleteSourceAndLayer(map, prevSourceName, prevLayer);
    });

    let newCityRes = await getCityMapResponse(cityId, countryName);

    setLayersData(newCityRes["newData"]);
    seCity(cityId);
    //adjust relevant map data 
    setSpacialLayersId({
        "base_layer_id": newCityRes["base_layer_id"],
        "building_res_layer_id": newCityRes["building_res_layer_id"],
        "building_to_color_layer_id": newCityRes["building_to_color_layer_id"],
        "parcel_to_color_layer_id": newCityRes["parcel_to_color_layer_id"],
        "parcel_results": newCityRes["parcel_results"]
    });

    //init state vars
    setLayers([]);
    // arrayForApiSecMap = [];
    setLayers3D([]);
    // layersSecMap = [];
    setFillLayer([]);
    // fillLayersSecMap = [];
    setResultsArr([]);
    // restOfCityArrSecMap = [];
    setActiveLayers([]);
    // activeLayersNewSecMap = [];
    setFirstLoad(true);
    // firstLoadSecMap = true;
    setNeedToDelete(false);
    // needToDeleteSecMap = false;
    setThreeDflag(true);
    // threeDflagSecMap = true;


    setLayerState(false);
    // layerStateSecMap = false;
    setFirstPaintOccur(false);
    // FirstPaintOccurSecMap = false;
    setReadyToIdle(false);
    // okToIdleSecMap = false;
    setLastPaintData({
        "text": "",
        "loc": [],
        'prevColoredBuildings': []
    })
    // prevColoredBuildingsSecMap = [];
    setReadyForNextMapClick(false);
    // okToNextPaintSecMap = false;
    // lastPaintFromCard = false;
    setClickOnRes(false);
    // clickOnResSecMap = false;
    setClickOnBoth(false);
    // clickOnBothSecMap = false;

    setBuildingsInBoth([]);
    // buildingsInBothSecMap = [];

    setFinishToLoadLayers(false);
    Object.entries(layersData.current).forEach(([key, value]) => {
        addLayer(value["layer_id"]);
    });
    setResultCenter(newCityRes["mapCenter"])
    map.setCenter(newCityRes["mapCenter"]);
    // double check later

    // let legendNode = document.getElementById("legend");
    // legendNode.innerHTML = '';

    // let menuNode = document.getElementById("menu");
    // menuNode.innerHTML = '';

    // get all layers data and colors form the api
    setLayersDataAndColors(await getLayerColor(layers, resultCenter, countryName, chosenLang));
    // init layers data to load - source array and results array
    let [sourceArrToLoad, resultsArrToSave] = await initLayersToLoad(layersData, layersDataAndColors);
    // save results array to state
    setResultsArr(resultsArrToSave);
    // load the layers to the map
    let [layers3D_temp, fillLayers_temp, activeLayers_temp] = await loadLayers(map, sourceArrToLoad, -1, firstLoad, layerState, layersData, false, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId['parcel_to_color_layer_id'], resultsArr, resultCenter, tilesDomainName, threeDFlag, layersDataAndColors, setTransactionsId, advance_search_data, transaction_popup, setMivnanimLayerId, mivnanim_data);
    // set the state variables
    setActiveLayers(activeLayers_temp);
    setLayers3D(layers3D_temp);
    setFillLayer(fillLayers_temp);
    setFinishToLoadLayers(true);

    // initLayersMenu();
    // initLegendNew();

    setReadyToIdle(true);
    // okToIdleSecMap = true
}
export { flyToClick, initLayersToLoad, loadLayers, getLayerColor, eventHandler3DLayers, eventHandler2DLayers, mapOnIdleHandler, mapOnClickHandler, adjustDynamicLegend, initThreeBox, reload3DLayers, updateLegend, hideTransactionLayer, reloadMap, deleteSourceAndLayer, exitCurrDraw, filterLayerArr, focusFeature, clearFilterLayerArr, clearFocusFeature, changeCity }