import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function GridDropDown(props) {
  
  return (
    <Box sx={{ minWidth: 120, maxWidth: 400, padding:1 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label-mode">Grid Mode</InputLabel>
        <Select
          labelId="select-label-mode"
          id="simple-select-mode"
          value={props.gridOptions.label}
          label="Grid Mode"
          onChange={props.handleChange}
          defaultValue={props.initialMode.value}
        >
          {props.gridOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}