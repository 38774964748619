import React from 'react'

export default function MultiColorLegendRow(props) {
  let sortedList = Array.from(props.resForLegend).sort(function (a, b) {
    const c = a.title,
      d = b.title;
    return c < d ? -1 : c > d ? 1 : 0;
  });

  return (<div key={props.layer.toString() + props.makeId(10)} id={props.layer.toString() + 'leg'} className='snap-m-r-10' style={props.layerData["visible_on_first_load"] == false ? { "display": "none" } : { "display": "block" }} >
    <span className='underline_text leg_span'>
      {props.layerData["layer_title"] + ":"}
    </span>
    {
      sortedList.map((currLayerColor) => {
        return <div className={currLayerColor["title"]} key={props.layer.toString() + props.makeId(20)}>
          <span className={props.layerData["layer_type"] == "line" ? 'legend-key-tab line_tab line_tab_inner' : 'legend-key-tab'} style={props.layerData["layer_type"] == "transactions" ? { 'backgroundColor': currLayerColor["color"], 'borderRadius': '50%' } : { 'backgroundColor': currLayerColor["color"] }}>
          </span>
          <span className='leg_span'>
            {currLayerColor["title"]}
          </span>
        </div>
      })
    }
  </div>
  )
}
