import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import './SnaplandMap.css';
import './MapControls.css';
import MapControls from './MapControls'
import MapLayers from './MapLayers'
import LayersMenu from './LayersMenu'
import Legend from './Legend'
import useMapStore from '../app/useMapStore'
import * as mapUtils from '../utils/mapUtils';

mapboxgl.accessToken = 'pk.eyJ1Ijoic25hcGxhbmQiLCJhIjoiY2wxOTFoZHd2MWplODNra2ExeDltbWNvYyJ9.u5L0-YCUN6FIQ2iAK4Tl-Q';
if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
    mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js');
}
export default function SnaplandMap(props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [buildingAndParcelToPaint, setBuildingAndParcelToPaint] = useState({
        "parcelsIdToPaint": [],
        "buildingsIdToPaint": []
    });
    const { setResultCenter, setLayersData, setChosenLang, addLayer, setSpacialLayersId, setNeedToDelete, setReadyForNextMapClick, setReadyForChangeCity, setLayerState, setFirstLoad, updateData, setClickOnRes, setClickOnBoth, addBuildingsInBoth, updateLastPaintData, addResultsArr, removeResultsArr, setReadyToIdle, setFirstPaintOccur, seCity, addPrevFilteredLayers, removePrevFilteredLayers, setLayers, setLayers3D, setFillLayer, setResultsArr, setThreeDFlag, setLastPaintData, setBuildingsInBoth, setFinishToLoadLayers, setActiveLayers, setLayersDataAndColors, setTransactionsId, setMivnanimLayerId, setSnapMapResize } = useMapStore(
        (state) => ({
            setResultCenter: state.setResultCenter,
            setLayersData: state.setLayersData,
            setChosenLang: state.setChosenLang,
            addLayer: state.addLayer,
            setSpacialLayersId: state.setSpacialLayersId,
            setNeedToDelete: state.setNeedToDelete,
            setReadyForNextMapClick: state.setReadyForNextMapClick,
            setReadyForChangeCity: state.setReadyForChangeCity,
            setLayerState: state.setLayerState,
            setFirstLoad: state.setFirstLoad,
            updateData: state.updateData,
            setClickOnRes: state.setClickOnRes,
            setClickOnBoth: state.setClickOnBoth,
            addBuildingsInBoth: state.addBuildingsInBoth,
            updateLastPaintData: state.updateLastPaintData,
            addResultsArr: state.addResultsArr,
            removeResultsArr: state.removeResultsArr,
            setReadyToIdle: state.setReadyToIdle,
            setFirstPaintOccur: state.setFirstPaintOccur,
            seCity: state.seCity,
            addPrevFilteredLayers: state.addPrevFilteredLayers,
            removePrevFilteredLayers: state.removePrevFilteredLayers,
            setLayers: state.setLayers,
            setLayers3D: state.setLayers3D,
            setFillLayer: state.setFillLayer,
            setResultsArr: state.setResultsArr,
            setThreeDFlag: state.setThreeDFlag,
            setLastPaintData: state.setLastPaintData,
            setBuildingsInBoth: state.setBuildingsInBoth,
            setFinishToLoadLayers: state.setFinishToLoadLayers,
            setActiveLayers: state.setActiveLayers,
            setLayersDataAndColors: state.setLayersDataAndColors,
            setTransactionsId: state.setTransactionsId,
            setMivnanimLayerId: state.setMivnanimLayerId,
            setSnapMapResize: state.setSnapMapResize
        })
    )

    // set state variable to update inside all functions
    const readyToIdle = useRef(useMapStore.getState().readyToIdle)
    useEffect(() => useMapStore.subscribe(
        state => (readyToIdle.current = state.readyToIdle)
    ), [])
    const needToDelete = useRef(useMapStore.getState().needToDelete)
    useEffect(() => useMapStore.subscribe(
        state => (needToDelete.current = state.needToDelete)
    ), [])

    const layerState = useRef(useMapStore.getState().layerState)
    useEffect(() => useMapStore.subscribe(
        state => (layerState.current = state.layerState)
    ), [])

    const layers3D = useRef(useMapStore.getState().layers3D)
    useEffect(() => useMapStore.subscribe(
        state => (layers3D.current = state.layers3D)
    ), [])

    const firstLoad = useRef(useMapStore.getState().firstLoad)
    useEffect(() => useMapStore.subscribe(
        state => (firstLoad.current = state.firstLoad)
    ), [])

    const layersData = useRef(useMapStore.getState().layersData)
    useEffect(() => useMapStore.subscribe(
        state => (layersData.current = state.layersData)
    ), [])
    const spacialLayersId = useRef(useMapStore.getState().spacialLayersId)
    useEffect(() => useMapStore.subscribe(
        state => (spacialLayersId.current = state.spacialLayersId)
    ), [])
    const clickOnRes = useRef(useMapStore.getState().clickOnRes)
    useEffect(() => useMapStore.subscribe(
        state => (clickOnRes.current = state.clickOnRes)
    ), [])

    const popUp3D = useRef(useMapStore.getState().popUp3D)
    useEffect(() => useMapStore.subscribe(
        state => (popUp3D.current = state.popUp3D)
    ), [])

    const chosenLang = useRef(useMapStore.getState().chosenLang)
    useEffect(() => useMapStore.subscribe(
        state => (chosenLang.current = state.chosenLang)
    ), [])

    const lastPaintData = useRef(useMapStore.getState().lastPaintData)
    useEffect(() => useMapStore.subscribe(
        state => (lastPaintData.current = state.lastPaintData)
    ), [])

    const resultsArr = useRef(useMapStore.getState().resultsArr)
    useEffect(() => useMapStore.subscribe(
        state => (resultsArr.current = state.resultsArr)
    ), [])

    const clickOnBoth = useRef(useMapStore.getState().clickOnBoth)
    useEffect(() => useMapStore.subscribe(
        state => (clickOnBoth.current = state.clickOnBoth)
    ), [])

    const buildingsInBoth = useRef(useMapStore.getState().buildingsInBoth)
    useEffect(() => useMapStore.subscribe(
        state => (buildingsInBoth.current = state.buildingsInBoth)
    ), [])

    const fillLayers = useRef(useMapStore.getState().fillLayers)
    useEffect(() => useMapStore.subscribe(
        state => (fillLayers.current = state.fillLayers)
    ), [])

    const resultCenter = useRef(useMapStore.getState().resultCenter)
    useEffect(() => useMapStore.subscribe(
        state => (resultCenter.current = state.resultCenter)
    ), [])

    const threeDFlag = useRef(useMapStore.getState().threeDFlag)
    useEffect(() => useMapStore.subscribe(
        state => (threeDFlag.current = state.threeDFlag)
    ), [])

    const layersDataAndColors = useRef(useMapStore.getState().layersDataAndColors)
    useEffect(() => useMapStore.subscribe(
        state => (layersDataAndColors.current = state.layersDataAndColors)
    ), [])

    const firstPaintOccur = useRef(useMapStore.getState().firstPaintOccur)
    useEffect(() => useMapStore.subscribe(
        state => (firstPaintOccur.current = state.firstPaintOccur)
    ), [])

    const city = useRef(useMapStore.getState().city)
    useEffect(() => useMapStore.subscribe(
        state => (city.current = state.city)
    ), [])

    const readyForNextMapClick = useRef(useMapStore.getState().readyForNextMapClick)
    useEffect(() => useMapStore.subscribe(
        state => (readyForNextMapClick.current = state.readyForNextMapClick)
    ), [])

    const drawVar = useRef(useMapStore.getState().drawVar)
    useEffect(() => useMapStore.subscribe(
        state => (drawVar.current = state.drawVar)
    ), [])

    const drawBlock = useRef(useMapStore.getState().drawBlock)
    useEffect(() => useMapStore.subscribe(
        state => (drawBlock.current = state.drawBlock)
    ), [])
    const transactionsLayerVisibility = useRef(useMapStore.getState().transactionsLayerVisibility)
    useEffect(() => useMapStore.subscribe(
        state => (transactionsLayerVisibility.current = state.transactionsLayerVisibility)
    ), [])

    const readyForChangeCity = useRef(useMapStore.getState().readyForChangeCity)
    useEffect(() => useMapStore.subscribe(
        state => (readyForChangeCity.current = state.readyForChangeCity)
    ), [])

    const layers = useRef(useMapStore.getState().layers)
    useEffect(() => useMapStore.subscribe(
        state => (layers.current = state.layers)
    ), [])

    const transactionsId = useRef(useMapStore.getState().transactionsId)
    useEffect(() => useMapStore.subscribe(
        state => (transactionsId.current = state.transactionsId)
    ), [])

    const dynamicLegendInterval = useRef(useMapStore.getState().dynamicLegendInterval)
    useEffect(() => useMapStore.subscribe(
        state => (dynamicLegendInterval.current = state.dynamicLegendInterval)
    ), [])

    const [mapLoad, setMapLoad] = useState(false);
    // Create the map instant on first load
    useEffect(() => {
        // if (map.current) return; // initialize map only once
        var snapMap = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/snapland/clbm8qebv003714pcuhrqhe1c',
            center: [props.mapLocation[0], props.mapLocation[1]],
            zoom: props.zoom,
            projection: 'globe',
            pitch: props.pitch,
            maxPitch: props.maxPitch,
            cooperativeGestures: false
        });
        map.current = snapMap;
        window.globalMap = map.current;
        // debugger
        // console.log(props.setSnapMap);
        // setSnapMap(snapMap);
        // init app state
        const resizeSnapMap = () => {
            map.current.resize();
        }
        setLayersData(props.data);
        setResultCenter(props.mapLocation);
        setChosenLang(props.chosenLang);
        setSpacialLayersId(props.spacialLayersId);
        seCity(props.city)
        // check later if can remove this loop and insert it to the initLayers func inside MapLayers comp
        Object.entries(props.data).forEach(([key, value]) => {
            addLayer(value["layer_id"]);
        });
        setMapLoad(true);
        const mapOnIdleHandler = (e) => {
            mapUtils.mapOnIdleHandler(map.current, readyToIdle, needToDelete, setNeedToDelete, setReadyForNextMapClick, setReadyForChangeCity, layerState, setLayerState, layers3D, firstLoad, setFirstLoad, layersData, popUp3D, transactionsLayerVisibility);
        }
        const mapOnClickHandler = (e) => {
            mapUtils.mapOnClickHandler(map.current, e, city, props.country, drawVar, drawBlock, layerState, setReadyForChangeCity, layersData, updateData, spacialLayersId, clickOnRes, setClickOnRes, setClickOnBoth, addBuildingsInBoth, popUp3D, chosenLang, lastPaintData, updateLastPaintData, resultsArr, addResultsArr, removeResultsArr, clickOnBoth, buildingsInBoth, setReadyToIdle, setNeedToDelete, fillLayers, resultCenter, props.tilesDomainName, threeDFlag, layersDataAndColors, firstPaintOccur, setFirstPaintOccur, needToDelete, readyForNextMapClick, setReadyForNextMapClick);
        }
        map.current.on('idle', mapOnIdleHandler);
        map.current.on('click', mapOnClickHandler);
        map.current.on("load", () => {
            setSnapMapResize(resizeSnapMap);
        });
    }, []);
    var transaction_popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true
    });
    const changeCityHandler = () => {
        mapUtils.changeCity(map.current, 4000, layers3D, fillLayers, layerState, readyForChangeCity, props.country, setLayersData, layersData, seCity, setSpacialLayersId, setLayers, setLayers3D, setFillLayer, setResultsArr, setFirstLoad, setNeedToDelete, setThreeDFlag, setLayerState, setFirstPaintOccur, setReadyToIdle, setLastPaintData, setReadyForNextMapClick, setClickOnRes, setClickOnBoth, setBuildingsInBoth, setFinishToLoadLayers, setActiveLayers, addLayer, setLayersDataAndColors, layers, setResultCenter, resultCenter, chosenLang, layersDataAndColors, firstLoad, spacialLayersId, resultsArr, props.tilesDomainName, threeDFlag, setTransactionsId, [], transaction_popup, setMivnanimLayerId, [], transactionsId, dynamicLegendInterval);
    }
    return (
        <div style={props.isGridMode ? { "position": "relative", "display": "contents" } : {"display": "contents" }}>
            <div ref={mapContainer} className="map-container" style={props.isGridMode ? { "position": "relative" } : { "position": "absolute" }}/>
            {mapLoad && <MapLayers map={map.current} country={props.country} tilesDomainName={props.tilesDomainName} transaction_popup={transaction_popup} />}
            {mapLoad && <LayersMenu map={map.current} tilesDomainName={props.tilesDomainName} />}
            {mapLoad && <Legend map={map.current} />}
            {mapLoad && <MapControls map={map.current} country={props.country} tilesDomainName={props.tilesDomainName} />}
            <button id="btn" className="btn" onClick={changeCityHandler} style={{ "position": "absolute", "left": "5rem" }} > החלף עיר לחיפה</button>
            <div className="calculation-box" style={{ "display": "none" }}>
                <p></p>
                <div id="calculated-area"></div>
            </div>
        </div>
    );
}
