import {create} from 'zustand';

import {devtools, persist} from 'zustand/middleware'

if (window.localStorage.mapStore !== ''){
    window.localStorage.mapStore = '';
}
const initialState = {
    // state vars //
    snapMapResize: null,
    city: null,
    // All layers data
    layersData: null,
    // All layers
    layers: [],
    // All 3D layers
    layers3D: [],
    // All fill layers
    fillLayers: [],
    // All results id
    resultsArr: [],
    // All current active layers
    activeLayers: [],
    // First load flag
    firstLoad: true,
    // flag for delete layers needed 
    needToDelete:false,
    // flag to check if the map is in 3d mode
    threeDFlag: true,
    // flag that indicate if the layers with suffix @
    layerState: false,
    // flag that indicate if the user click on the map for the first time
    firstPaintOccur: false,
    // buildings in both result parcel and not
    buildingsInBoth: [],
    // the center on the result
    resultCenter: [],
    // flag that indicate if the map currently have regularMap style or not
    regularMapStyleFlag: true,
    // flag that indicate if the load of map layers is done
    finishToLoadLayers: false,
    // flag that indicate if the map is ready for next click for paint building and parcel
    readyForNextMapClick: false,
    // flag that indicate if the map is ready for change city
    readyForChangeCity: false,
    // flag that indicate if the map is during changing base map
    changeBaseMap: false,
    // flag that indicate if the map is ready for idle
    readyToIdle: false,
    // User chosen language
    chosenLang: '',
    // 
    spacialLayersId:{},
    // getColor response
    layersDataAndColors: [],
    // transactions layer id currently just israel
    transactionsId: null,
    // transactions layer visibility currently just israel
    transactionsLayerVisibility: "none",
    // the last paint data for changing styles
    lastPaintData: {
        "text": "",
        "loc": [],
        'prevColoredBuildings': []
    },
    // last click where on result building result layer
    clickOnRes: false,
    // last click where on result building and parcel result layer
    clickOnBoth: false,
    // 3D pop-up label
    popUp3D: null,
    // flag that indicate if the user is currently in the middle of drawing
    drawBlock: false,
    // the draw element
    drawVar: null,
    // object that holds all the previous layers names and ids that filtered from table
    prevFilteredLayers: {},
    // object that holds all the interval pointers of blink focus layers filtered from table
    interValsObj: {},
    // object that holds all the timeouts clear pointers of blink focus layers filtered from table
    clearTimerObj: {},
    // mivnan layer id
    mivnanimLayerId: null,
    dynamicLegendInterval: null,
  }
const mapStore = (set) => ({
    ...initialState,
    // state functions 
    setSnapMapResize: (snapMapResizeToSet) => {
        set((state) => ({
            snapMapResize: snapMapResizeToSet
        }))
    },
    seCity: (cityToSet) => {
        set((state) => ({
            city: cityToSet
        }))
    },
    setLayersData: (dataToSet) => {
        set((state) => ({
            layersData: dataToSet
        }))
    },
    updateData: (layerToSet,layerObj) => {
        set((state) => ({
            layersData: {...state.layersData, [layerToSet]:layerObj}
        }))
    },
    setLayers: (layersToSet) => {
        set((state) => ({
            layers: layersToSet
        }))
    },
    addLayer: (layerId) => {
        set((state) => ({
            layers: [layerId, ...state.layers],
        }))
    },
    removeLayer: (layerId) => {
        set((state) => ({
            layers: state.layers.filter((c) => c !== layerId)
        }))
    },
    add3DLayer: (layerId) => {
        set((state) => ({
            layers3D: [layerId, ...state.layers3D],
        }))
    },
    remove3DLayer: (layerId) => {
        set((state) => ({
            layers3D: state.layers3D.filter((c) => c !== layerId)
        }))
    },
    setLayers3D:(val) => {
        set((state) => ({
            layers3D: val
        }))
    },
    addFillLayer: (layerId) => {
        set((state) => ({
            fillLayers: [layerId, ...state.fillLayers],
        }))
    },
    removeFillLayer: (layerId) => {
        set((state) => ({
            fillLayers: state.fillLayers.filter((c) => c !== layerId)
        }))
    },
    setFillLayer: (val) => {
        set((state) => ({
            fillLayers: val
        }))
    },

    setLastPaintData: (val) => {
        set((state) => ({
            lastPaintData: val
        }))
    },
    updateLastPaintData: (keyToSet, valToSet) => {
        set((state) => ({
            lastPaintData: {...state.lastPaintData, [keyToSet]: valToSet}
        }))
    },
    setResultsArr: (arrToSet) => {
        set((state) => ({
            resultsArr: arrToSet
        }))
    },
    addResultsArr: (layerIdToSet) => {
        set((state) => ({
            resultsArr: [...state.resultsArr, layerIdToSet],
        }))
    },
    removeResultsArr: (layerId) => {
        set((state) => ({
            resultsArr: state.resultsArr.filter((c) => c != layerId)
        }))
    },
    addActiveLayers: (layerId) => {
        set(prev => ({
            activeLayers: [layerId, ...prev.activeLayers],
        }))
    },
    setBuildingsInBoth: (buildingsInBothToSet) => {
        set((state) => ({
            buildingsInBoth: buildingsInBothToSet
        }))
    },
    addBuildingsInBoth: (building) => {
        set((state) => ({
            buildingsInBoth: [building, ...state.buildingsInBoth],
        }))
    },
    removeBuildingsInBoth: (building) => {
        set((state) => ({
            buildingsInBoth: state.buildingsInBoth.filter((c) => c != building)
        }))
    },
    removeActiveLayers: (layerId) => {
        set(prev => ({
            activeLayers: prev.activeLayers.filter((c) => c != layerId)
        }))
    },
    setActiveLayers: (activeLayersToSet) => {
        set((state) => ({
            activeLayers: activeLayersToSet,
        }))
    },
    setReadyForNextMapClick: (readyForNextMapClickToSet) => {
        set((state) => ({
            readyForNextMapClick: readyForNextMapClickToSet,
        }))
    },
    setReadyForChangeCity: (readyForChangeCityToSet) => {
        set((state) => ({
            readyForChangeCity: readyForChangeCityToSet,
        }))
    },
    setLayersDataAndColors: (layersDataAndColorsToSet) => {
        set((state) => ({
            layersDataAndColors: layersDataAndColorsToSet,
        }))
    },
    setReadyToIdle: (val) => {
        set((state) => ({
            readyToIdle: val,
        }))
    },
    setFirstLoad: (val) => {
        set((state) => ({
            firstLoad: val
        }))
    },
    setFinishToLoadLayers: (val) => {
        set((state) => ({
            finishToLoadLayers: val
        }))
    },
    
    setNeedToDelete: (val) => {
        set((state) => ({
            needToDelete: val
        }))
    },
    setThreeDFlag: (val) => {
        set((state) => ({
            threeDFlag: val
        }))
    },
    setLayerState: (val) => {
        set((state) => ({
            layerState: val
        }))
    },
    setFirstPaintOccur: (val) => {
        set((state) => ({
            firstPaintOccur: val
        }))
    },
    setResultCenter: (val) => {
        set((state) => ({
            resultCenter: val
        }))
    },
    setRegularMapStyleFlag: (val) => {
        set((state) => ({
            regularMapStyleFlag: val
        }))
    },
    setChosenLang: (val) =>{
        set((state) => ({
            chosenLang: val
        }))
    },
    setSpacialLayersId: (val) =>{
        set((state) => ({
            spacialLayersId: val
        }))
    },
    setTransactionsId: (idToSet) => {
        set((state) => ({
            transactionsId: idToSet
        }))
    },
    setTransactionsLayerVisibility: (transactionsLayerVisibilityToSet) => {
        set((state) => ({
            transactionsLayerVisibility: transactionsLayerVisibilityToSet
        }))
    },
    setClickOnBoth: (clickOnBothToSet) => {
        set((state) => ({
            clickOnBoth: clickOnBothToSet
        }))
    },
    setClickOnRes: (clickOnResToSet) => {
        set((state) => ({
            clickOnRes: clickOnResToSet
        }))
    },
    setPopUp3D: (popUp3DToSet) => {
        set((state) => ({
            popUp3D: popUp3DToSet
        }))
    },
    setChangeBaseMap:(changeBaseMapSet) => {
        set((state) => ({
            changeBaseMap: changeBaseMapSet
        }))
    },
    setDrawBlock:(drawBlockSet) => {
        set((state) => ({
            drawBlock: drawBlockSet
        }))
    },
    setDrawVar:(drawVarToSet) => {
        set((state) => ({
            drawVar: drawVarToSet
        }))
    },
    addPrevFilteredLayers: (layerId, arrToSet) => {
        set((state) => ({
            prevFilteredLayers: {...state.prevFilteredLayers, [layerId]: arrToSet}
        }))
    },
    removePrevFilteredLayers: (layerId) => {
        set((state) => ({
            prevFilteredLayers: Object.fromEntries(Object.entries(state.prevFilteredLayers).filter(([key, value]) => key != layerId))
        }))
    },
    updatePrevFilteredLayers: (layerToSet,layerArr) => {
        set((state) => ({
            prevFilteredLayers: {...state.prevFilteredLayers, [layerToSet]:layerArr}
        }))
    },
    addInterValsObj: (layerId, intervalToSet) => {
        set((state) => ({
            interValsObj: {...state.interValsObj, [layerId]: intervalToSet}
        }))
    },
    removeInterValsObj: (layerId) => {
        set((state) => ({
            interValsObj: Object.fromEntries(Object.entries(state.interValsObj).filter(([key, value]) => key != layerId))
        }))
    },
    addClearTimerObj: (layerId, clearTimerToSet) => {
        set((state) => ({
            clearTimerObj: {...state.clearTimerObj, [layerId]: clearTimerToSet}
        }))
    },
    removeClearTimerObj: (layerId) => {
        set((state) => ({
            clearTimerObj: Object.fromEntries(Object.entries(state.clearTimerObj).filter(([key, value]) => key != layerId))
        }))
    },
    setMivnanimLayerId: (mivnanimLayerIdToSet) => {
        set((state) => ({
            mivnanimLayerId: mivnanimLayerIdToSet
        }))
    },
    setDynamicLegendInterval: (dynamicLegendIntervalToSet) => {
        set((state) => ({
            dynamicLegendInterval: dynamicLegendIntervalToSet
        }))
    },
    reset: () => set(initialState)
})

const useMapStore = create(
    devtools(
        persist(mapStore, {
            name: "mapStore",
        })
    )
)


export default useMapStore;