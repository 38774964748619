var DATA1 = {
    "layer1435": {
        "layer_id": 1435,
        "layer type": "fill-extrusion"
    },
    "layer1438": {
        "layer_id": 1438,
        "layer type": "fill",
        "results": [
            "70822144"
        ]
    },
    "layer1436": {
        "layer_id": 1436,
        "layer type": "fill-extrusion",
        "results": [
            17127
        ]
    },
    "layer1439": {
        "layer_id": 1439,
        "layer type": "fill",
        "results": []
    },
    "layer1437": {
        "layer_id": 1437,
        "layer type": "fill-extrusion",
        "results": []
    },
    "layer1459": {
        "layer_id": 1459,
        "layer type": "fill"
    },
    "layer1462": {
        "layer_id": 1462,
        "layer type": "fill"
    },
    "layer1463": {
        "layer_id": 1463,
        "layer type": "fill"
    },
    "layer1467": {
        "layer_id": 1467,
        "layer type": "line"
    },
    "layer1591": {
        "layer_id": 1591,
        "layer type": "symbol"
    },
    "layer2951": {
        "layer_id": 2951,
        "layer type": "line"
    },
    "layer3728": {
        "layer_id": 3728,
        "layer type": "fill"
    },
    "layer1866": {
        "layer_id": 1866,
        "layer type": "transactions"
    }
    // ,
    // "layer1940": {
    //     "layer_id": 1940,
    //     "layer type": "mivnan_labels"
    // }
        

}

var mapProps = {
    data: DATA1,
    infoPage: true,
    mapLocation: [
        34.77008085802532,
        32.05448445469347
    ],
    zoom: 17,
    pitch: 50,
    maxPitch: 60,
    tilesDomainName: "tile.snap.land",
    country: 'Israel',
    chosenLang: "he",
    city: 100,
    spacialLayersId: {
        "base_layer_id": "1435",
        "building_res_layer_id": "1436",
        "parcel_results": "1438",
        "building_to_color_layer_id": "1437",
        "parcel_to_color_layer_id": "1439"
    }
}

export { mapProps };