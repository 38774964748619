import * as mapUtils from '../utils/mapUtils'
class HomeControl {
    constructor(eventHandle = function(){} ) {
        this._eventHandler = eventHandle;
    }

    onAdd(map) {
        this._map = map;
        let _this = this;
        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon mapHomeButton';
        this._btn.type = 'button';
        this._btn.onclick = this._eventHandler;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);
        return this._container;
    }

    onRemove() {
        this._btn.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

class ResetControl {
    constructor(eventHandle = function(){} ) {
        this._eventHandler = eventHandle;
    }
    onAdd(map) {
        this._map = map;
        let _this = this;
        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon clearButton';
        this._btn.type = 'button';
        this._btn.onclick = this._eventHandler;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);
        return this._container;
    }

    onRemove() {
        this._btn.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

class MapStyleToggle {

    constructor(eventHandle = function(){} ) {
        this._eventHandler = eventHandle;
    }

    onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement('button');
        this._btn.id = 'btn-map-style';
        // this._btn.disabled = true;
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-style-toggle-satellite';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Toggle Style';

        this._btn.onclick = this._eventHandler;

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}

class PitchAnd3DToggle {

    constructor(eventHandle = function(){} ) {
        this._eventHandler = eventHandle;
    }

    onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement('button');
        this._btn.id = 'btn-3D-sec';
        this._btn.disabled = true;
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d disable';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Toggle Pitch';

        this._btn.onclick = this._eventHandler;

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}

// export {HomeControl, ResetControl, MapStyleToggle, PitchAnd3DToggle};
export {HomeControl, PitchAnd3DToggle, MapStyleToggle, ResetControl};