import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useEffect } from 'react';
import * as snapMapControls from './CustomControls'
import useMapStore from '../app/useMapStore'
import * as mapUtils from '../utils/mapUtils'
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from '@turf/turf';

export default function MapControls(props) {
    // get relevant state fields
    var { setRegularMapStyleFlag, setThreeDFlag, setFirstPaintOccur, setClickOnRes, setClickOnBoth, updateData, updateLastPaintData, setBuildingsInBoth, removeResultsArr, setNeedToDelete, setReadyToIdle, setTransactionsLayerVisibility, setActiveLayers, setLayerState, setChangeBaseMap, setFirstLoad, setReadyForChangeCity, setReadyForNextMapClick, setFinishToLoadLayers, setResultsArr, setDrawBlock, setDrawVar } = useMapStore(
        (state) => ({
            setRegularMapStyleFlag: state.setRegularMapStyleFlag,
            setThreeDFlag: state.setThreeDFlag,
            setFirstPaintOccur: state.setFirstPaintOccur,
            setClickOnRes: state.setClickOnRes,
            setClickOnBoth: state.setClickOnBoth,
            updateData: state.updateData,
            updateLastPaintData: state.updateLastPaintData,
            setBuildingsInBoth: state.setBuildingsInBoth,
            removeResultsArr: state.removeResultsArr,
            setNeedToDelete: state.setNeedToDelete,
            setReadyToIdle: state.setReadyToIdle,
            setTransactionsLayerVisibility: state.setTransactionsLayerVisibility,
            setActiveLayers: state.setActiveLayers,
            setLayerState: state.setLayerState,
            setChangeBaseMap: state.setChangeBaseMap,
            setFirstLoad: state.setFirstLoad,
            setReadyForChangeCity: state.setReadyForChangeCity,
            setReadyForNextMapClick: state.setReadyForNextMapClick,
            setFinishToLoadLayers: state.setFinishToLoadLayers,
            setResultsArr: state.setResultsArr,
            setDrawBlock: state.setDrawBlock,
            setDrawVar: state.setDrawVar

        })
    )

    // set state variable to update inside all functions
    const threeDFlag = useRef(useMapStore.getState().threeDFlag)
    useEffect(() => useMapStore.subscribe(
        state => (threeDFlag.current = state.threeDFlag)
    ), [])

    const regularMapStyleFlag = useRef(useMapStore.getState().regularMapStyleFlag)
    useEffect(() => useMapStore.subscribe(
        state => (regularMapStyleFlag.current = state.regularMapStyleFlag)
    ), [])

    const layers3D = useRef(useMapStore.getState().layers3D)
    useEffect(() => useMapStore.subscribe(
        state => (layers3D.current = state.layers3D)
    ), [])

    const layerState = useRef(useMapStore.getState().layerState)
    useEffect(() => useMapStore.subscribe(
        state => (layerState.current = state.layerState)
    ), [])

    const resultCenter = useRef(useMapStore.getState().resultCenter)
    useEffect(() => useMapStore.subscribe(
        state => (resultCenter.current = state.resultCenter)
    ), [])

    const firstPaintOccur = useRef(useMapStore.getState().firstPaintOccur)
    useEffect(() => useMapStore.subscribe(
        state => (firstPaintOccur.current = state.firstPaintOccur)
    ), [])

    const readyForNextMapClick = useRef(useMapStore.getState().readyForNextMapClick)
    useEffect(() => useMapStore.subscribe(
        state => (readyForNextMapClick.current = state.readyForNextMapClick)
    ), [])

    const clickOnRes = useRef(useMapStore.getState().clickOnRes)
    useEffect(() => useMapStore.subscribe(
        state => (clickOnRes.current = state.clickOnRes)
    ), [])

    const layersData = useRef(useMapStore.getState().layersData)
    useEffect(() => useMapStore.subscribe(
        state => (layersData.current = state.layersData)
    ), [])

    const spacialLayersId = useRef(useMapStore.getState().spacialLayersId)
    useEffect(() => useMapStore.subscribe(
        state => (spacialLayersId.current = state.spacialLayersId)
    ), [])

    const lastPaintData = useRef(useMapStore.getState().lastPaintData)
    useEffect(() => useMapStore.subscribe(
        state => (lastPaintData.current = state.lastPaintData)
    ), [])

    const buildingsInBoth = useRef(useMapStore.getState().buildingsInBoth)
    useEffect(() => useMapStore.subscribe(
        state => (buildingsInBoth.current = state.buildingsInBoth)
    ), [])

    const resultsArr = useRef(useMapStore.getState().resultsArr)
    useEffect(() => useMapStore.subscribe(
        state => (resultsArr.current = state.resultsArr)
    ), [])

    const fillLayers = useRef(useMapStore.getState().fillLayers)
    useEffect(() => useMapStore.subscribe(
        state => (fillLayers.current = state.fillLayers)
    ), [])

    const layersDataAndColors = useRef(useMapStore.getState().layersDataAndColors)
    useEffect(() => useMapStore.subscribe(
        state => (layersDataAndColors.current = state.layersDataAndColors)
    ), [])

    const transactionsLayerVisibility = useRef(useMapStore.getState().transactionsLayerVisibility)
    useEffect(() => useMapStore.subscribe(
        state => (transactionsLayerVisibility.current = state.transactionsLayerVisibility)
    ), [])

    const firstLoad = useRef(useMapStore.getState().firstLoad)
    useEffect(() => useMapStore.subscribe(
        state => (firstLoad.current = state.firstLoad)
    ), [])

    const layers = useRef(useMapStore.getState().layers)
    useEffect(() => useMapStore.subscribe(
        state => (layers.current = state.layers)
    ), [])
    
    const drawBlock = useRef(useMapStore.getState().drawBlock)
    useEffect(() => useMapStore.subscribe(
        state => (drawBlock.current = state.drawBlock)
    ), [])

    const drawVar = useRef(useMapStore.getState().drawVar)
    useEffect(() => useMapStore.subscribe(
        state => (drawVar.current = state.drawVar)
    ), [])



    const homeControlHandler = function () {
        mapUtils.exitCurrDraw(drawVar, setDrawBlock);
        mapUtils.flyToClick(props.map, resultCenter, threeDFlag);
    }

    const clearControlHandler = async function () {
        // marker2.remove();
        mapUtils.exitCurrDraw(drawVar, setDrawBlock);
        if (firstPaintOccur.current && readyForNextMapClick.current) {
            setFirstPaintOccur(false);
            window.labelSecond.setCoords([]);
            if (clickOnRes.current) {
                let currObj = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']];
                currObj["results"] = currObj["results"].concat(lastPaintData.current['prevColoredBuildings']);
                // DATA1["layer" + spacialLayersId.current['building_res_layer_id']]["results"] = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']]["results"].concat(lastPaintData.current['prevColoredBuildings'])
                updateData("layer" + spacialLayersId.current['building_res_layer_id'], currObj);
            } else {
                if (lastPaintData.current['prevColoredBuildings'].length != 0) {
                    for (let i = 0; i < lastPaintData.current['prevColoredBuildings'].length; i++) {
                        let index = resultsArr.current.indexOf(lastPaintData.current['prevColoredBuildings'][i]);
                        let bothIndex = buildingsInBoth.current.indexOf(lastPaintData.current['prevColoredBuildings'][i])
                        if (index != -1 && bothIndex == -1) {
                            removeResultsArr(lastPaintData.current['prevColoredBuildings'][i]);
                        }
                    }
                }
                if (buildingsInBoth.current.length != 0) {
                    let currObj = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']];
                    currObj["results"] = currObj["results"].concat(buildingsInBoth.current);
                    updateData("layer" + spacialLayersId.current['building_res_layer_id'], currObj);
                    // DATA1["layer" + spacialLayersId.current['building_res_layer_id']]["results"] = layersData.current["layer" + spacialLayersId.current['building_res_layer_id']]["results"].concat(buildingsInBoth.current)
                }
            }
            let currObj = layersData.current["layer" + spacialLayersId.current['building_to_color_layer_id']];
            currObj["results"] = [];
            updateData("layer" + spacialLayersId.current['building_to_color_layer_id'], currObj);
            // DATA1["layer" + spacialLayersId.current['building_to_color_layer_id']]["results"] = [];
            updateLastPaintData('prevColoredBuildings', []);
            setBuildingsInBoth([]);
            setClickOnRes(false);
            // hasBothBuildingSecMap = false;
            setClickOnBoth(false);

            await mapUtils.reload3DLayers(props.map, undefined, setReadyToIdle, setNeedToDelete, fillLayers, layersData, layerState, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId.current['parcel_to_color_layer_id'], resultsArr, resultCenter, props.tilesDomainName, threeDFlag, layersDataAndColors, transactionsLayerVisibility);

            props.map.setPaintProperty(spacialLayersId.current['parcel_to_color_layer_id'], 'fill-color',
                ['match', ['number', ['get', 'ischosen']], 1, 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']
            );
            props.map.setPaintProperty(spacialLayersId.current['parcel_to_color_layer_id'], 'fill-outline-color',
                ['match', ['number', ['get', 'ischosen']], 1, 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']
            );
            await mapUtils.updateLegend(props.map, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId);
            await mapUtils.updateLegend(props.map, spacialLayersId.current['parcel_to_color_layer_id'], spacialLayersId);
        }
        // Reset layers btn in info page
        document.querySelectorAll('button[data-layer]').forEach((el) => {
            el.innerHTML = 'הצגה על גבי מפה';
            el.classList.remove("more_info_btn_outline");
        });
        // $('button[data-layer]').each((index, element) => {
        //     let curr = $(element);
        //     curr.text('הצגה על גבי מפה')
        //     curr.removeClass("more_info_btn_outline");
        // })
        if (transactionsLayerVisibility.current == 'visible') {
            await mapUtils.updateLegend(props.map, 1866, spacialLayersId);
            document.getElementById("1866").classList.remove('active');
            // $(`a#secondprops.map866`).removeClass('active');
            mapUtils.hideTransactionLayer(props.map, setTransactionsLayerVisibility);
        }
        //get all the layers results idds
        for (const key in layersData.current) {
            let layerId = layersData.current[key]["layer_id"];
            let layerData = layersDataAndColors.current.filter(function (item) { return item.id === layerId; });
            if (layerData.length == 0) {
                continue;
            }
            layerData = layerData[0];
            if ((layerData["layer_type"] != "fill-extrusion") && (layerData["layer_type"] != "transactions") && !layerData["visible_on_first_load"] && props.map.getLayoutProperty(layerId, 'visibility') == 'visible') {
                props.map.setLayoutProperty(layerId, 'visibility', 'none');
                document.getElementById(`${layerId}`).classList.remove('active');
                mapUtils.updateLegend(props.map, layerId, spacialLayersId);
                // $(`a#secondMap${layerId}`).removeClass('active');

                // let layerIndex = activeLayersNewSecMap.indexOf(layerId);
                // if (layerIndex > -1) {
                //     activeLayersNewSecMap.splice(layerIndex, 1)
                // }
            }

        }
    }

    const mapStyleToggleHandler = async function () {
        mapUtils.exitCurrDraw(drawVar, setDrawBlock);
        setChangeBaseMap(true);
        setReadyToIdle(false);
        if (regularMapStyleFlag.current) {
            //switch to satellite
            props.map.setStyle('mapbox://styles/snapland/cl236gzvr000514ruiagw9v2h');
            this.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-style-toggle-regular'
            setRegularMapStyleFlag(!regularMapStyleFlag.current);
        } else {
            //switch to regular
            props.map.setStyle('mapbox://styles/snapland/clbm8qebv003714pcuhrqhe1c');
            this.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-style-toggle-satellite';
            setRegularMapStyleFlag(!regularMapStyleFlag.current);
            // reloadMap();
        }
        // wait until all style will remove
        props.map.once('styledata', async () => {
            await mapUtils.reloadMap(props.map, setActiveLayers, firstLoad, setFirstLoad, setNeedToDelete, layerState, setLayerState, setReadyForNextMapClick, setReadyForChangeCity, layersData, layersDataAndColors, spacialLayersId, lastPaintData, resultCenter, props.tilesDomainName, threeDFlag, setFinishToLoadLayers, layers, resultsArr, setResultsArr);
            setReadyToIdle(true);
            setChangeBaseMap(false);
            });

    };

    var prevPitch = 70;
    const threeDControlHandler = function () {
        let currentBearing = props.map.getBearing();
        if (!threeDFlag.current) {
            // Need to change to 3D

            mapUtils.exitCurrDraw(drawVar, setDrawBlock);
            layers3D.current.forEach((layer) => {
                if (layerState.current) {
                    layer += '@'
                }
                if (props.map.getPaintProperty(layer, 'fill-extrusion-height') == 0) {
                    props.map.setPaintProperty(layer, 'fill-extrusion-height', {
                        'type': 'identity',
                        'property': 'height'
                    })
                    props.map.setPaintProperty(layer, 'fill-extrusion-base', {
                        'type': 'identity',
                        'property': 'base'
                    })
                }
            })
            props.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            let options = { pitch: prevPitch, bearing: currentBearing };
            if (props.map.getZoom() > 11) {
                options.zoom = props.map.getZoom();
            }
            props.map.easeTo(options);
            this.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d';
            setThreeDFlag(!threeDFlag.current);
        } else {
            // Need to change to 2D

            props.map.setTerrain();
            layers3D.current.forEach((layer) => {
                if (layerState.current) {
                    layer += '@'
                }
                if (props.map.getPaintProperty(layer, 'fill-extrusion-height') != 0) {
                    props.map.setPaintProperty(layer, 'fill-extrusion-height', 0)
                    props.map.setPaintProperty(layer, 'fill-extrusion-base', 0)
                }
            })
            let currentPitch = props.map.getPitch();
            // Save last pitch
            prevPitch = currentPitch;
            props.map.easeTo({ pitch: 0, bearing: currentBearing });
            this.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
            setThreeDFlag(!threeDFlag.current);
        }
    };

    useEffect(() => {
        var draw = new MapboxDraw({
            displayControlsDefault: false,
            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
                polygon: true,
                line_string: true
            }
        });
        setDrawVar(draw);
        if (props.country == "Israel") {
            let nav = new mapboxgl.NavigationControl();
            // props.map.addControl(new mapboxgl.ScaleControl(),'bottom-left');
            props.map.addControl(nav, 'top-left');
            props.map.addControl(new snapMapControls.HomeControl(homeControlHandler), 'top-left');
            props.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
            props.map.addControl(new snapMapControls.ResetControl(clearControlHandler), 'top-left');
            props.map.addControl(draw, 'top-left');
            props.map.addControl(new snapMapControls.PitchAnd3DToggle(threeDControlHandler), 'top-left');
            props.map.addControl(new snapMapControls.MapStyleToggle(mapStyleToggleHandler), 'top-left');
        } else {
            let nav = new mapboxgl.NavigationControl();
            props.map.addControl(nav, 'top-right');
            // props.map.addControl(draw,'top-right');
            // props.map.addControl(new mapboxgl.ScaleControl(),'bottom-right');
            props.map.addControl(new snapMapControls.PitchAnd3DToggle(threeDControlHandler), 'top-right');
            // props.map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
            // props.map.addControl(new snapMapControls.ResetControl(), 'top-right');
            props.map.addControl(new snapMapControls.HomeControl(homeControlHandler), 'top-right');
            // props.map.addControl(new snapMapControls.MapStyleToggle({ defaultStyle: 'regular' }), 'top-right');
        }
        var click_to_draw = 'לחץ על מנת לצייר';
        var meters = 'מטרים';
        var square_meters = 'מטרים רבועים';
        props.map.on('draw.create', updateArea);
        props.map.on('draw.delete', updateArea);
        props.map.on('draw.update', updateArea);
        props.map.on('draw.modechange', createDrawBlock);

        function createDrawBlock(e) {
            if (threeDFlag.current) {
                document.getElementById("btn-3D-sec").click();
            }
            let data = drawVar.current.getAll();
            let curr_draw_mode = drawVar.current.getMode();
            let drawDiv = document.querySelector(".calculation-box");
            if (curr_draw_mode == 'draw_polygon' || curr_draw_mode == 'draw_line_string') {
                drawDiv.style.display = 'block';
                // $(".calculation-box").show();
                drawDiv.innerHTML = `<p><strong>${click_to_draw}</strong></p>`
                // $(".calculation-box").html(`<p><strong>${click_to_draw}</strong></p>`);
                let pids = []
                // ID of the added template empty feature
                const lid = data.features[data.features.length - 1].id

                data.features.forEach((f) => {
                    if ((f.geometry.type === 'Polygon' || f.geometry.type === 'LineString') && f.id !== lid) {
                        pids.push(f.id)
                    }
                })
                drawVar.current.delete(pids);
            } else {
                if (drawVar.current.getAll().features.length == 0) {
                    drawDiv.style.display = 'none';
                    drawDiv.innerHTML = '';
                }
            }
            setTimeout(() => {
                setDrawBlock(!drawBlock.current);
            }, 500);

        }

        function updateArea(e) {
            let data = drawVar.current.getAll();
            let drawDiv = document.querySelector(".calculation-box");
            if (data.features.length > 0) {
                let curr_draw_mode = drawVar.current.getMode();
                if (curr_draw_mode == 'draw_line_string') {
                    let cords_arr = data.features[0].geometry.coordinates;
                    let dist = 0;
                    for (let index = 0; index < cords_arr.length - 1; index++) {
                        let from_point = cords_arr[index];
                        let to_point = cords_arr[index + 1];
                        dist += turf.distance(from_point, to_point, { units: 'meters' });
                    }
                    let rounded_distance = Math.round(dist * 100) / 100;
                    drawDiv.style.display = 'block';
                    // $(".calculation-box").show();
                    drawDiv.innerHTML = `<p><strong>${rounded_distance}</strong></p><p>${meters}</p>`;
                    // $(".calculation-box").html(`<p><strong>${rounded_distance}</strong></p><p>${meters}</p>`);

                } else if (curr_draw_mode == 'draw_polygon') {
                    drawDiv.style.display = 'block';
                    let area = turf.area(data);
                    // Restrict the area to 2 decimal points.
                    let rounded_area = Math.round(area * 100) / 100;
                    drawDiv.innerHTML = `<p><strong>${rounded_area}</strong></p><p>${square_meters}</p>`;
                }
            } else {
                // $(".calculation-box").hide();
                drawDiv.style.display = 'none';
                drawDiv.innerHTML = '';
                if (e.type !== 'draw.delete')
                    alert('Click the map to draw a polygon.');
            }

        }
    }, []);
    return (
        <div>
        </div>
    );
}
