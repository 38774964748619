import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useEffect } from 'react';
import useMapStore from '../app/useMapStore';
import * as mapUtils from '../utils/mapUtils';
import "./MapLayers.css";
import advance_search_data from '../utils/advance_data_charts.json';
import mivnanim_data from '../utils/mivnanim.json';


export default function MapLayers(props) {
    // get relevant state fields
    const { setResultsArr, setFinishToLoadLayers, setActiveLayers, setLayersDataAndColors, setLayers3D, setFillLayer, setPopUp3D, addPrevFilteredLayers, removePrevFilteredLayers, updatePrevFilteredLayers, removeInterValsObj, removeClearTimerObj, addInterValsObj, addClearTimerObj, setTransactionsId,setMivnanimLayerId } = useMapStore(
        (state) => ({
            setResultsArr: state.setResultsArr,
            setFinishToLoadLayers: state.setFinishToLoadLayers,
            setActiveLayers: state.setActiveLayers,
            setLayersDataAndColors: state.setLayersDataAndColors,
            setLayers3D: state.setLayers3D,
            setFillLayer: state.setFillLayer,
            setPopUp3D: state.setPopUp3D,
            addPrevFilteredLayers: state.addPrevFilteredLayers, 
            removePrevFilteredLayers: state.removePrevFilteredLayers,
            updatePrevFilteredLayers: state.updatePrevFilteredLayers ,
            removeInterValsObj: state.removeInterValsObj,
            removeClearTimerObj:state.removeClearTimerObj,
            addInterValsObj: state.addInterValsObj,
            addClearTimerObj: state.addClearTimerObj,
            setTransactionsId: state.setTransactionsId,
            setMivnanimLayerId: state.setMivnanimLayerId
        })
    )

    // set state variable to update inside all functions
    const threeDFlag = useRef(useMapStore.getState().threeDFlag)
    useEffect(() => useMapStore.subscribe(
        state => (threeDFlag.current = state.threeDFlag)
    ), [])

    const layers = useRef(useMapStore.getState().layers)
    useEffect(() => useMapStore.subscribe(
        state => (layers.current = state.layers)
    ), [])

    const chosenLang = useRef(useMapStore.getState().chosenLang)
    useEffect(() => useMapStore.subscribe(
        state => (chosenLang.current = state.chosenLang)
    ), [])

    const layersData = useRef(useMapStore.getState().layersData)
    useEffect(() => useMapStore.subscribe(
        state => (layersData.current = state.layersData)
    ), [])

    const resultsArr = useRef(useMapStore.getState().resultsArr)
    useEffect(() => useMapStore.subscribe(
        state => (resultsArr.current = state.resultsArr)
    ), [])

    const resultCenter = useRef(useMapStore.getState().resultCenter)
    useEffect(() => useMapStore.subscribe(
        state => (resultCenter.current = state.resultCenter)
    ), [])

    const spacialLayersId = useRef(useMapStore.getState().spacialLayersId)
    useEffect(() => useMapStore.subscribe(
        state => (spacialLayersId.current = state.spacialLayersId)
    ), [])

    const layerState = useRef(useMapStore.getState().layerState)
    useEffect(() => useMapStore.subscribe(
        state => (layerState.current = state.layerState)
    ), [])

    const layersDataAndColors = useRef(useMapStore.getState().layersDataAndColors)
    useEffect(() => useMapStore.subscribe(
        state => (layersDataAndColors.current = state.layersDataAndColors)
    ), [])

    const lastPaintData = useRef(useMapStore.getState().lastPaintData)
    useEffect(() => useMapStore.subscribe(
      state => (lastPaintData.current = state.lastPaintData)
    ), [])

    const popUp3D = useRef(useMapStore.getState().popUp3D)
    useEffect(() => useMapStore.subscribe(
      state => (popUp3D.current = state.popUp3D)
    ), [])

    const firstLoad = useRef(useMapStore.getState().firstLoad)
    useEffect(() => useMapStore.subscribe(
      state => (firstLoad.current = state.firstLoad)
    ), [])

    const regularMapStyleFlag = useRef(useMapStore.getState().regularMapStyleFlag)
    useEffect(() => useMapStore.subscribe(
      state => (regularMapStyleFlag.current = state.regularMapStyleFlag)
    ), [])
    
    const interValsObj = useRef(useMapStore.getState().interValsObj)
    useEffect(() => useMapStore.subscribe(
      state => (interValsObj.current = state.interValsObj)
    ), [])
    
    const clearTimerObj = useRef(useMapStore.getState().clearTimerObj)
    useEffect(() => useMapStore.subscribe(
      state => (clearTimerObj.current = state.clearTimerObj)
    ), [])

    const prevFilteredLayers = useRef(useMapStore.getState().prevFilteredLayers)
    useEffect(() => useMapStore.subscribe(
      state => (prevFilteredLayers.current = state.prevFilteredLayers)
    ), [])


    window.addMapFilterFromTable = (layerId, arrToFilter)=>{
        mapUtils.filterLayerArr(props.map, layerId, arrToFilter, addPrevFilteredLayers);
    }
    
    window.clearMapFilterFromTable = (layerId)=>{
        mapUtils.clearFilterLayerArr(props.map, layerId, removePrevFilteredLayers, interValsObj, clearTimerObj, removeInterValsObj, removeClearTimerObj);
    }

    window.focusMapFeature = (layerId, id)=>{
        debugger
        mapUtils.focusFeature(props.map, layerId, id, prevFilteredLayers, updatePrevFilteredLayers, interValsObj, addInterValsObj, removeInterValsObj, clearTimerObj, addClearTimerObj, removeClearTimerObj, props.country, removePrevFilteredLayers);
    }
    

    
    

    // load all layers on component loads
    useEffect(async () => {
        // get all layers data and colors form the api
        setLayersDataAndColors(await mapUtils.getLayerColor(layers, resultCenter, props.country, chosenLang));
        // init layers data to load - source array and results array
        let [sourceArrToLoad, resultsArrToSave] = await mapUtils.initLayersToLoad(layersData, layersDataAndColors);
        // save results array to state
        setResultsArr(resultsArrToSave);
        // load the layers to the map
        let [layers3D, fillLayers, activeLayers] = await mapUtils.loadLayers(props.map, sourceArrToLoad, -1, firstLoad, layerState, layersData, false, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId['parcel_to_color_layer_id'], resultsArr, resultCenter, props.tilesDomainName, threeDFlag, layersDataAndColors, setTransactionsId, advance_search_data, props.transaction_popup, setMivnanimLayerId, mivnanim_data);
        // set the state variables
        setFinishToLoadLayers(true);
        setActiveLayers(activeLayers);
        setLayers3D(layers3D);
        setFillLayer(fillLayers);
        
        // add elevation layer to the map
        props.map.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
            'tileSize': 512,
            'maxzoom': 14
        });
        props.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        // init 3D package to the map for z-point labels
        mapUtils.initThreeBox(props.map,spacialLayersId.current['building_to_color_layer_id'], lastPaintData, setPopUp3D, popUp3D);

    }, []);

    return (
        <div>
        </div>
    );
}

