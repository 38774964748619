import React, { useRef, useEffect, useState } from 'react';
import Split from 'react-split-grid'
import './MapsGrid.css';
import SnaplandMap from './SnaplandMap';
import { mapProps } from '../tel_aviv_res_react'
import Iframe from 'react-iframe'
import useMapStore from '../app/useMapStore';
import GridDropDown from './GridDropDown';
import MapsDropDown from './MapsDropDown';
import { createReparentableSpace } from 'react-reparenting';

const { Reparentable, sendReparentableChild } = createReparentableSpace();

export default function MapsGrid() {
  const { reset } = useMapStore(
    (state) => ({
      reset: state.reset
    })
  );
  const snapMapResize = useRef(useMapStore.getState().snapMapResize)
  useEffect(() => useMapStore.subscribe(
    state => (snapMapResize.current = state.snapMapResize)
  ), [])
  const [gridMode, setGridMode] = useState({ gridSize: 2, isGridMode: true, text: '2-Grid' })
    ;

  // mapProps['childToParent'] = childToParent;
  var onDragEndHandler = (direction, track) => {
    snapMapResize.current();
  }
  const gridOptions = [
    { value: 1, label: 'Single' },
    { value: 2, label: '2-Grid' },
    { value: 4, label: '4-Grid' }
  ];
  const [mapsOptions, setMapsOptions] = useState([
    { value: 1, label: 'snaplandMap', disable: false, currCell: 1 },
    { value: 2, label: 'Satellite', disable: false, currCell: 3 },
    { value: 3, label: 'GovMap', disable: false, currCell: 2 },
    { value: 4, label: 'ofek', disable: false, currCell: 4 }
  ]);
  const handleChangeMap = (event, prev, callerCell) => {
    let cellToSent = event.currCell;
    setMapsOptions(mapsOptions.map((option) => {
      if (option.label === event.label) {
        option.disable = true;
        option.currCell = callerCell;
      } else {
        option.disable = option.label == prev.label ? false : option.disable;
        option.currCell = option.label == prev.label ? cellToSent : option.currCell;
      }
      return option;
    }));
  }
  const handleChange = (event) => {
    setGridMode({ gridSize: event.target.value, isGridMode: event.target.value === 1 ? false : true, text: event.target.value === 1 ? 'Single' : event.target.value === 2 ? '2-Grid' : '4-Grid' });
    setTimeout(() => {
      snapMapResize.current();
    }, 100);

  };
  const changeParent = function(from, to) {
      
    sendReparentableChild(from, to, 0, 0);
  }
  window.changeParent = changeParent;
  
  return (
    <div>
      <GridDropDown gridMode={gridMode} handleChange={handleChange} gridOptions={gridOptions} initialMode={{ value: 2, label: '2-Grid' }} />

      <Split minSize={200} onDragEnd={onDragEndHandler} render={({ getGridProps, getGutterProps, }) => (
        <div className={gridMode.gridSize === 1 ? "grid" : gridMode.gridSize === 2 ? "grid grid-2-cells" : "grid grid-2-cells grid-4-cells"} {...getGridProps()}>
          {/* cell 1 */}
          <div className='parent-div'>
            <Reparentable id="parentA">
              <div className='parent-div'>
                <MapsDropDown mapsOptions={mapsOptions} handleChange={handleChangeMap} initialMap={{ value: 1, label: 'snaplandMap' }} cellNum={1} />
                <SnaplandMap {...mapProps} isGridMode={gridMode.isGridMode} />
              </div>
            </Reparentable>
          </div>
          <div className="gutter-col gutter-col-1" {...getGutterProps('column', 1)} style={gridMode.gridSize < 2 ? { "display": "none" } : { "display": "block" }} />
          {/* cell 2 */}
          <div style={gridMode.gridSize < 2 ? { "display": "none" } : { "display": "block" }}>
            <Reparentable id="parentB">
              <div>
                <MapsDropDown mapsOptions={mapsOptions} handleChange={handleChangeMap} initialMap={{ value: 3, label: 'GovMap' }} cellNum={2} />
                <Iframe url="https://www.govmap.gov.il/map.html?bb=1&amp;zb=1&amp;in=1&amp;c=34.76981416227356,32.05441837486401&amp;z=10&amp;lay=SUB_GUSH_ALL,PARCEL_ALL"
                  width="100%"
                  height="100%"
                  id=""
                  className=""
                  display="block"
                  position="relative" />
              </div>
            </Reparentable>
          </div>
          {/* cell 3 */}
          <div style={gridMode.gridSize !== 4 ? { "display": "none" } : { "display": "block" }}>
            <Reparentable id="parentC">
              <div>
                {gridMode.gridSize === 4 && <MapsDropDown mapsOptions={mapsOptions} handleChange={handleChangeMap} initialMap={{ value: 2, label: 'Satellite' }} cellNum={3} />}
                <Iframe url="https://www.govmap.gov.il/map.html?bb=1&amp;zb=1&amp;in=1&amp;c=34.76981416227356,32.05441837486401&amp;z=10&amp;lay=SUB_GUSH_ALL,PARCEL_ALL"
                  width="100%"
                  height="100%"
                  id=""
                  className=""
                  display="block"
                  position="relative" />
              </div>
            </Reparentable>
          </div>

          <div className="gutter-row gutter-row-1" {...getGutterProps('row', 1)} style={gridMode.gridSize !== 4 ? { "display": "none" } : { "display": "block" }} />
          {/* cell 4 */}
          <div style={gridMode.gridSize !== 4 ? { "display": "none" } : { "display": "block" }}>
            <Reparentable id="parentC">
              <div>
                {gridMode.gridSize === 4 && <MapsDropDown mapsOptions={mapsOptions} handleChange={handleChangeMap} initialMap={{ value: 4, label: 'ofek' }} cellNum={4} />}
                <Iframe url="http://127.0.0.1:5500/snapland-mapbox-react/src/utils/ofek_test_template.html"
                  width="100%"
                  height="100%"
                  id=""
                  className=""
                  display="block"
                  position="relative" />
              </div>
            </Reparentable>
          </div>
        </div>
      )} />
    </div>
  )
}
