import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useEffect, useState } from 'react';
import useMapStore from '../app/useMapStore';
import * as mapUtils from '../utils/mapUtils';
import './Legend.css';
import MultiColorLegendRow from './MultiColorLegendRow'
import SymbolLegendRow from './SymbolLegendRow'
import SingleLegendRow from './SingleLegendRow'

export default function Legend(props) {
  function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  // get relevant state fields
  const { setReadyToIdle, setDynamicLegendInterval } = useMapStore(
    (state) => ({
      setReadyToIdle: state.setReadyToIdle,
      setDynamicLegendInterval: state.setDynamicLegendInterval
    })
  )

  // set state variable to update inside all functions
  const finishToLoadLayers = useRef(useMapStore.getState().finishToLoadLayers)
  useEffect(() => useMapStore.subscribe(
    state => (finishToLoadLayers.current = state.finishToLoadLayers)
  ), [])
  const layers = useRef(useMapStore.getState().layers)
  useEffect(() => useMapStore.subscribe(
    state => (layers.current = state.layers)
  ), [])
  const activeLayers = useRef(useMapStore.getState().activeLayers)
  useEffect(() => useMapStore.subscribe(
    state => (activeLayers.current = state.activeLayers)
  ), [])

  const layersDataAndColors = useRef(useMapStore.getState().layersDataAndColors)
  useEffect(() => useMapStore.subscribe(
    state => (layersDataAndColors.current = state.layersDataAndColors)
  ), [])

  const spacialLayersId = useRef(useMapStore.getState().spacialLayersId)
  useEffect(() => useMapStore.subscribe(
    state => (spacialLayersId.current = state.spacialLayersId)
  ), [])

  const readyToIdle = useRef(useMapStore.getState().readyToIdle)
  useEffect(() => useMapStore.subscribe(
    state => (readyToIdle.current = state.readyToIdle)
  ), [])

  const layerState = useRef(useMapStore.getState().layerState)
  useEffect(() => useMapStore.subscribe(
    state => (layerState.current = state.layerState)
  ), [])

  const transactionsId = useRef(useMapStore.getState().transactionsId)
  useEffect(() => useMapStore.subscribe(
    state => (transactionsId.current = state.transactionsId)
  ), [])

  const needToDelete = useRef(useMapStore.getState().needToDelete)
  useEffect(() => useMapStore.subscribe(
    state => (needToDelete.current = state.needToDelete)
  ), [])

  const readyForNextMapClick = useRef(useMapStore.getState().readyForNextMapClick)
  useEffect(() => useMapStore.subscribe(
    state => (readyForNextMapClick.current = state.readyForNextMapClick)
  ), [])

  const mivnanimLayerId = useRef(useMapStore.getState().mivnanimLayerId)
  useEffect(() => useMapStore.subscribe(
    state => (mivnanimLayerId.current = state.mivnanimLayerId)
  ), [])




  var [legendContent, setLegendContent] = useState([])

  useEffect(async () => {
    if (!finishToLoadLayers.current) return;
    // remove duplications
    let filteredArr = layersDataAndColors.current.reduce((acc, current) => {
      let x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    //sort lex. order
    let sortedList = Array.from(filteredArr).sort(function (a, b) {
      const c = a.layer_title,
        d = b.layer_title;
      return c < d ? -1 : c > d ? 1 : 0;
    });
    setLegendContent(sortedList.map((layer) => {
      let layerData = layersDataAndColors.current.filter(function (item) { return item.id == layer.id; });

      let resForLegend;
      if (layerData[0]["legend_col"] != null) {
        resForLegend = layerData;
      }
      if (layerData.length === 1) {
        layerData = layerData[0];
      } else {
        resForLegend = layerData;
        layerData = layerData[0];
      }

      // load the legend components
      if (resForLegend != null) {
        return <MultiColorLegendRow key={layer.id.toString() + makeId(10)} resForLegend={resForLegend} layer={layer.id} layerData={layerData} makeId={makeId} />
      } else {
        if (layerData["layer_type"] == "symbol" || layerData["layer_type"] == "mivnan_labels") {
          return <SymbolLegendRow key={layer.id.toString() + makeId(10)} resForLegend={resForLegend} layer={layer.id} layerData={layerData} makeId={makeId} />
        } else {
          return <SingleLegendRow key={layer.id.toString() + makeId(10)} resForLegend={resForLegend} layer={layer.id} layerData={layerData} makeId={makeId} spacialLayersId={spacialLayersId} />
        }
      }
    }
    ));
    let dynamicLegendThreshold = 1000;
    setDynamicLegendInterval(
      setInterval(function () {
        if (readyToIdle.current && needToDelete.current == false && readyForNextMapClick.current) {
          // dynamic legend
          mapUtils.adjustDynamicLegend(props.map, layerState, spacialLayersId.current['parcel_results'], spacialLayersId.current['parcel_to_color_layer_id'], spacialLayersId.current['building_to_color_layer_id'], transactionsId.current, mivnanimLayerId.current);
        }
      }, dynamicLegendThreshold)
    );
    setReadyToIdle(true);
  }, [finishToLoadLayers.current]);

  return (
    <div className='map-overlay' id='legend'>
      {legendContent}
    </div>
  );
}

