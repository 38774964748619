import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useEffect, useState } from 'react';
import useMapStore from '../app/useMapStore';
import * as mapUtils from '../utils/mapUtils';
import './LayersMenu.css';

export default function LayersMenu(props) {
  // get relevant state fields
  const { setReadyForNextMapClick, setReadyForChangeCity, addActiveLayers, removeActiveLayers, setReadyToIdle, setNeedToDelete,setTransactionsLayerVisibility, addResultsArr, removeResultsArr } = useMapStore(
    (state) => ({
      setReadyForNextMapClick: state.setReadyForNextMapClick,
      setReadyForChangeCity: state.setReadyForChangeCity,
      addActiveLayers: state.addActiveLayers,
      removeActiveLayers: state.removeActiveLayers,
      setReadyToIdle: state.setReadyToIdle,
      setNeedToDelete: state.setNeedToDelete,
      setTransactionsLayerVisibility: state.setTransactionsLayerVisibility,
      addResultsArr: state.addResultsArr,
      removeResultsArr: state.removeResultsArr
    })
  )

  // set state variable to update inside all functions
  const finishToLoadLayers = useRef(useMapStore.getState().finishToLoadLayers)
  useEffect(() => useMapStore.subscribe(
    state => (finishToLoadLayers.current = state.finishToLoadLayers)
  ), [])
  const layers = useRef(useMapStore.getState().layers)
  useEffect(() => useMapStore.subscribe(
    state => (layers.current = state.layers)
  ), [])
  const activeLayers = useRef(useMapStore.getState().activeLayers)
  useEffect(() => useMapStore.subscribe(
    state => (activeLayers.current = state.activeLayers)
  ), [])

  const layersDataAndColors = useRef(useMapStore.getState().layersDataAndColors)
  useEffect(() => useMapStore.subscribe(
    state => (layersDataAndColors.current = state.layersDataAndColors)
  ), [])

  const spacialLayersId = useRef(useMapStore.getState().spacialLayersId)
  useEffect(() => useMapStore.subscribe(
    state => (spacialLayersId.current = state.spacialLayersId)
  ), [])

  const readyForNextMapClick = useRef(useMapStore.getState().readyForNextMapClick)
  useEffect(() => useMapStore.subscribe(
    state => (readyForNextMapClick.current = state.readyForNextMapClick)
  ), [])

  const layerState = useRef(useMapStore.getState().layerState)
  useEffect(() => useMapStore.subscribe(
    state => (layerState.current = state.layerState)
  ), [])

  const layersData = useRef(useMapStore.getState().layersData)
  useEffect(() => useMapStore.subscribe(
    state => (layersData.current = state.layersData)
  ), [])

  const resultsArr = useRef(useMapStore.getState().resultsArr)
  useEffect(() => useMapStore.subscribe(
    state => (resultsArr.current = state.resultsArr)
  ), [])

  const fillLayers = useRef(useMapStore.getState().fillLayers)
  useEffect(() => useMapStore.subscribe(
    state => (fillLayers.current = state.fillLayers)
  ), [])

  const resultCenter = useRef(useMapStore.getState().resultCenter)
  useEffect(() => useMapStore.subscribe(
    state => (resultCenter.current = state.resultCenter)
  ), [])

  const threeDFlag = useRef(useMapStore.getState().threeDFlag)
  useEffect(() => useMapStore.subscribe(
    state => (threeDFlag.current = state.threeDFlag)
  ), [])
  
  const transactionsLayerVisibility = useRef(useMapStore.getState().transactionsLayerVisibility)
  useEffect(() => useMapStore.subscribe(
    state => (transactionsLayerVisibility.current = state.transactionsLayerVisibility)
  ), [])
  


  var [allLayersATag, setAllLayersATag] = useState(<div></div>)

  const layer3DOnClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    mapUtils.eventHandler3DLayers(props.map, e.target.id, setReadyForNextMapClick, setReadyForChangeCity, layerState, layersData, activeLayers, removeActiveLayers, resultsArr, setReadyToIdle, setNeedToDelete, fillLayers, addActiveLayers, spacialLayersId.current['building_to_color_layer_id'], spacialLayersId.current['parcel_to_color_layer_id'], resultCenter, props.tilesDomainName, threeDFlag, layersDataAndColors, spacialLayersId,  addResultsArr, removeResultsArr, transactionsLayerVisibility);
  }

  const layerOnClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    mapUtils.eventHandler2DLayers(props.map, e.target.id, layersData, transactionsLayerVisibility, setTransactionsLayerVisibility, layerState, spacialLayersId);
  }

  useEffect(async () => {
    if (!finishToLoadLayers.current) return;
    // remove duplications
    let filteredArr = layersDataAndColors.current.reduce((acc, current) => {
      let x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    //sort lex. order
    let sortedList = Array.from(filteredArr).sort(function (a, b) {
      const c = a.layer_title,
        d = b.layer_title;
      return c < d ? -1 : c > d ? 1 : 0;
    });
    // set init the layer in the menu
    setAllLayersATag(sortedList.map((layer) => {
      // skip layers for click on map
      if (layer.id == spacialLayersId.current['building_to_color_layer_id'] || layer.id == spacialLayersId.current['parcel_to_color_layer_id']) return;
      return <a key={layer.id.toString()}
        id={layer.id.toString()}
        href='#'
        className={layer.visible_on_first_load ? "active disabledMenuButton" : "disabledMenuButton"}
        onClick={layer.layer_type == "fill-extrusion" ? layer3DOnClickHandler : layerOnClickHandler}
      >
        {layer.layer_title}
      </a>
    }
    ));
  }, [finishToLoadLayers.current]);

  return (
    <nav id="menu">
      {allLayersATag}
    </nav>
  );
}

