import React, { useRef, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { createReparentableSpace } from 'react-reparenting';
import Iframe from 'react-iframe';
import SnaplandMap from './SnaplandMap';
import { mapProps } from '../tel_aviv_res_react';
import useMapStore from '../app/useMapStore';
import GridDropDown from './GridDropDown';
import MapsDropDown from './MapsDropDown';
import Split from 'react-split-grid'

const { Reparentable, sendReparentableChild } = createReparentableSpace();

export default function MapsGrid() {
  const cellParentMap = ['parentA', 'parentB', 'parentC', 'parentD'];
  const snapMapResize = useRef(useMapStore.getState().snapMapResize);
  
  useEffect(() => {
    useMapStore.subscribe(state => {
      snapMapResize.current = state.snapMapResize;
    });
  }, []);
  
  const [gridMode, setGridMode] = useState({
    gridSize: 2,
    isGridMode: true,
    text: '2-Grid'
  });

  const gridOptions = [
    { value: 1, label: 'Single' },
    { value: 2, label: '2-Grid' },
    { value: 4, label: '4-Grid' }
  ];

  const [mapsOptions, setMapsOptions] = useState([
    { value: 1, label: 'snaplandMap', disable: false, currCell: 1 },
    { value: 2, label: 'Satellite', disable: false, currCell: 3 },
    { value: 3, label: 'GovMap', disable: false, currCell: 2 },
    { value: 4, label: 'ofek', disable: false, currCell: 4 }
  ]);

  const handleChangeMap = (event, prev, callerCell, isFirst) => {
    const cellToSent = event.currCell;
    setMapsOptions(mapsOptions => mapsOptions.map(option => {
      if (option.label === event.label) {
        option.currCell = callerCell;
      } else {
        option.currCell = option.label === prev.label ? cellToSent : option.currCell;
      }
      return option;
    }));
    if (!isFirst) {
      swapChildrens(cellParentMap[callerCell - 1], cellParentMap[cellToSent - 1]);
    }
  };

  const handleChange = event => {
    setGridMode({
      gridSize: event.target.value,
      isGridMode: event.target.value === 1 ? false : true,
      text: event.target.value === 1 ? 'Single' : event.target.value === 2 ? '2-Grid' : '4-Grid'
    });
    setTimeout(() => {
      snapMapResize.current();
    }, 100);
  };

  const swapChildrens = (from, to) => {
    sendReparentableChild(from, to, 0, 0);
    sendReparentableChild(to, from, 1, 0);
  };

  return (
    <div>
      <GridDropDown
        gridMode={gridMode}
        handleChange={handleChange}
        gridOptions={gridOptions}
        initialMode={{ value: 2, label: '2-Grid' }}
     
        />
        <Grid
          container
          width={'50%'}
          rowSpacing={{ xs: 1, sm: 2, md: 3 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          height={'100vh'}
        >
          <Grid xs={gridMode.gridSize === 1 ? 12 : 6}>
            <MapsDropDown
              mapsOptions={mapsOptions}
              handleChange={handleChangeMap}
              initialMap={{ value: 1, label: 'snaplandMap' }}
              cellNum={1}
            />
            <Reparentable id="parentA">
              <SnaplandMap {...mapProps} isGridMode={true} />
            </Reparentable>
          </Grid>
      
          <Grid xs={6} display={gridMode.gridSize < 2 ? 'none' : null}>
            <MapsDropDown
              mapsOptions={mapsOptions}
              handleChange={handleChangeMap}
              initialMap={{ value: 3, label: 'GovMap' }}
              cellNum={2}
            />
            <Reparentable id="parentB">
              <Iframe
                url="https://www.govmap.gov.il/map.html?bb=1&amp;zb=1&amp;in=1&amp;c=34.76981416227356,32.05441837486401&amp;z=10&amp;lay=SUB_GUSH_ALL,PARCEL_ALL"
                width="100%"
                height="100%"
                id=""
                className=""
                display="block"
                position="relative"
              />
            </Reparentable>
          </Grid>
      
          <Grid xs={6} display={gridMode.gridSize !== 4 ? 'none' : null}>
            {gridMode.gridSize === 4 && (
              <MapsDropDown
                mapsOptions={mapsOptions}
                handleChange={handleChangeMap}
                initialMap={{ value: 2, label: 'Satellite' }}
                cellNum={3}
              />
            )}
            <Reparentable id="parentC">
              <Iframe
                url="http://127.0.0.1:5500/snapland-mapbox-react/src/utils/ofek_test_template.html"
                width="100%"
                height="100%"
                id=""
                className=""
                display="block"
                position="relative"
              />
            </Reparentable>
          </Grid>
      
          <Grid xs={6} display={gridMode.gridSize !== 4 ? 'none' : null}>
            {gridMode.gridSize === 4 && (
              <MapsDropDown
                mapsOptions={mapsOptions}
                handleChange={handleChangeMap}
                initialMap={{ value: 4, label: 'ofek' }}
                cellNum={4}
              />
            )}
            <Reparentable id="parentD">
              <Iframe
                url="http://127.0.0.1:5500/snapland-mapbox-react/src/utils/ofek_test_template.html"
                width="100%"
                height="100%"
                id=""
                className=""
                display="block"
                position="relative"
              />
            </Reparentable>
          </Grid>
        </Grid>
        <div>Yoad</div>
      </div>
);
}      