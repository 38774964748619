import React from 'react';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import MapsGrid from './components/MapsGrid';
import MapsGrid_v3 from './components/MapsGrid_v3';



ReactDOM.render(
        <MapsGrid_v3/>,
    document.getElementById('root')
);
