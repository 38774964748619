import React from 'react'

export default function SingleLegendRow(props) {
  return (
    <div key={props.layer.toString() + props.makeId(10)} id={props.layer.toString() + 'leg'} className='snap-m-r-10' style={ ( (props.spacialLayersId.current["building_to_color_layer_id"] == props.layer.toString() ||
    props.spacialLayersId.current["parcel_to_color_layer_id"] == props.layer.toString() ) || props.layerData["visible_on_first_load"] == false) ? { "display": "none" } : { "display": "block" }}>
      <span
        className={props.layerData["layer_type"] == "line" ? "legend-key line_tab" : "legend-key"}
        style={{ 'backgroundColor': props.layerData["color"] }}
      >
      </span>
      <span className='leg_span'>
        {props.layerData["layer_title"]}
      </span>
    </div>
  )
}
