import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './MapsDropDown.css'

export default function MapsDropDown(props) {
  debugger
  // var mapsOptions = [
  //   { value: 1, label: 'snaplandMap', disable: false, currCell: 1 },
  //   { value: 2, label: 'Satellite', disable: false, currCell: 3 },
  //   { value: 3, label: 'GovMap', disable: false, currCell: 2 },
  //   { value: 4, label: 'ofek', disable: false, currCell: 4 }
  // ]
  const [currVal, setCurrVal] = React.useState(props.initialMap);

  const [prev, setPrev] = React.useState(props.initialMap);
  const [isFirst, SetIsFirst] = React.useState(true);
  function handleChangeLocal(event) {
    // if (isFirst === undefined) isFirst = false;
    setCurrVal(event.target.value);
    let eToSend = props.mapsOptions.find((option) => option.value === event.target.value);
    props.handleChange(eToSend, prev, props.cellNum, isFirst);
    setPrev(eToSend);
  }
  React.useEffect(() => {
    debugger
    handleChangeLocal({target: props.initialMap});
    SetIsFirst(false);
  }, []);

  return (
    <Box className={"dropDownOverlay"} sx={{ minWidth: 120, maxWidth: 400, padding: 1 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label-map">Map</InputLabel>
        <Select
          labelId="select-label-map"
          id="simple-select-map"
          value={currVal}
          label="Grid Mode"
          onChange={handleChangeLocal}
          defaultValue={props.initialMap}
        >

          {/* <MenuItem key='1' value={1} >
            snaplandMap
          </MenuItem>
          <MenuItem key='2' value={2} >
            Satellite
          </MenuItem>
          <MenuItem key='3' value={3} >
            GovMap
          </MenuItem>
          <MenuItem key='4' value={4} >
            ofek
          </MenuItem> */}
          {props.mapsOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.value == currVal} >
              {option.label}
            </MenuItem>

          ))}
        </Select>
      </FormControl>
    </Box>
  );
}