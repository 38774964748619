import React from 'react'

export default function SymbolLegendRow(props) {
  return (
    <div key={props.layer.toString() + props.makeId(10)} id={props.layer.toString() + 'leg'} className='leg_symbol_div snap-m-r-10' style={props.layerData["visible_on_first_load"] == false ? { "display": "none" } : { "display": "block" }}>
      <img
        src={props.layerData["layer_type"] !== "mivnan_labels" ? props.layerData["color"].replace('.png', '.svg') : props.layerData["color"]}
        className='symbol_layer'></img>
      <span>
        {props.layerData["layer_title"]}
      </span>
    </div>
  )
}
